import React from 'react'
import Price from '../../../util/Price'
import Button from '../../../util/Button'

const ImpurityRow = ({
    impurity,
    currencySymbol,
    currencyCode,
    unitCode,
    onClickSell,
}) => (
    <div className="flex mb-2">
        <div className="w-1/3 text-primary text-xl font-bold pt-2">
            {impurity.name}
        </div>
        <div className="w-1/3 ml-[8%] mt-2">
            <Price
                currencySymbol={currencySymbol}
                currencyCode={currencyCode}
                price={impurity.sellPrice}
                unitCode={unitCode}
            />
        </div>
        <div className="w-1/3 flex pt-2">
            <Button
                className="btn-primary p-0 w-full h-8"
                onClick={() => onClickSell(impurity)}
            >
                {`Sell ${impurity.name}`}
            </Button>
        </div>
    </div>
)

export default ImpurityRow
