import { useQuery, gql } from '@apollo/client'

export const DEFAULT_CURRENCY_FRAGMENT = gql`
    fragment DefaultCurrency on Currency {
        code,
        symbol,
        name,
    }
`

export const DEFAULT_UNIT_FRAGMENT = gql`
    fragment DefaultUnit on Unit {
        code,
        name,
        milligram,
    }
`

export const CONFIG_QUERY = gql`
    query config {
        currencies {
            ...DefaultCurrency
        },
        units {
            ...DefaultUnit
        },
    }
    ${DEFAULT_CURRENCY_FRAGMENT}
    ${DEFAULT_UNIT_FRAGMENT}
`

const useFetchConfigCache = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(CONFIG_QUERY)
    const { currencies = [], units = [] } = data

    return {
        error,
        loading,
        currencies,
        units,
    }
}

const ConfigCache = ({
    children,
}) => {
    const {
        error,
        loading,
        currencies,
        units,
    } = useFetchConfigCache()

    return children({
        error,
        loading,
        currencies,
        units,
    })
}

export default ConfigCache
