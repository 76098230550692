import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import FocusDidMount from '../../util/FocusDidMount'
import InputGroup from '../../util/InputGroup'
import PhoneInput from '../../util/PhoneInput'

const LoginForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <form className="form" onSubmit={onSubmit}>
            <FocusDidMount>
                {(ref) => (
                    <InputGroup errorMessage={getErrorMessages('phone')}>
                        <PhoneInput
                            {...getInputProps('phone')}
                            inputRef={ref}
                            placeholder="Phone number"
                        />
                    </InputGroup>
                )}
            </FocusDidMount>
            <div className="mb-4 flex items-center justify-between">
                <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                    onClick={onSubmit}
                >
                    Send code
                </button>
                <Link className="hyperlink" to={routes.login()}>
                    Back to choose
                </Link>
            </div>
        </form>
    )
}

export default LoginForm
