import React from 'react'
import { useApolloClient, gql } from '@apollo/client'
import {
    unitToMilligram,
    getInitalQuantity,
} from '../../../../util/units'
import { isValidNewOrderForm } from '../../../../validation/new-order/newOrder'
import { getNewOrderFormErrors } from '../../../../validation/new-order/newOrderErrors'
import Modal from '../../../util/Modal'
import FormState from '../../../util/FormState'
import NewOrderModal from './NewOrderModal'

const MAX_ORDER_REQUESTS = 5

const CREATE_ORDER_REQUEST_MUTATION = gql`
    mutation createOrderRequest($input: OrderRequestInput!) {
        createOrderRequest(input: $input) {
            orderRequest {
                id
                unitCode
                spotPrice
                currencyCode
                requestedAt
                impurityId
            }
        }
    }
`

const CREATE_ORDER_MUTATION = gql`
    mutation createOrder($input: OrderInput!) {
        createOrder(input: $input) {
            order {
                id
                side
                quantity
                quantityUnitCode
                price
                priceUnitCode
                currencyCode
                assetCode
                impurity {
                    id
                    name
                }
            }
        }
    }
`

const createOrderRequest = async (apollo, input) => {
    const mutation = CREATE_ORDER_REQUEST_MUTATION
    const variables = { input }
    const response = await apollo.mutate({
        mutation,
        variables,
    })
    return response.data.createOrderRequest.orderRequest
}

const createOrder = async (apollo, input) => {
    const mutation = CREATE_ORDER_MUTATION
    const variables = { input }
    const response = await apollo.mutate({
        mutation,
        variables,
    })
    return response.data.createOrder.order
}

const INITIAL_STATE = {
    requestIsLoading: true,
    requestIsSuccess: false,
    requestIsFailure: false,
    orderRequest: null,
    orderRequestError: null,
    isAuthenticating: false,
    orderIsLoading: false,
    orderIsSuccess: false,
    orderIsFailure: false,
    order: null,
    orderError: null,
    requestCount: 1,
}

const NewOrderModalWithState = ({
    isOpen,
    side,
    asset,
    initialSpotPrice,
    unitCode,
    currencyCode,
    dismissIsDisabled,
    onToggleDisableDismiss,
    onDismiss,
    maxTradeLimit,
    minTradeLimit,
    availableUnits,
    impurityId = null,
    impurityName = null,
}) => {
    const apollo = useApolloClient()
    const assetId = asset.id
    const orderRequestInput = {
        assetId,
        side,
        unitCode,
        currencyCode,
        impurityId,
    }

    return (
        <Modal
            aria-labelledby="Create new order"
            isOpen={isOpen}
            dismissIsDisabled={dismissIsDisabled}
            onDismiss={onDismiss}
        >
            <FormState
                initialState={INITIAL_STATE}
                getFormErrors={getNewOrderFormErrors}
                initialValues={{
                    quantity: getInitalQuantity(minTradeLimit, unitCode),
                    quantityUnitCode: unitCode,
                }}
                didMount={async ({ setState }) => {
                    const input = {
                        ...orderRequestInput,
                        spotPrice: initialSpotPrice,
                    }
                    try {
                        const orderRequest = await createOrderRequest(apollo, input)
                        setState({
                            orderRequest,
                            requestIsLoading: false,
                        })
                    } catch (e) {
                        setState({
                            errorMessage: e.message,
                            orderRequest: null,
                            requestIsLoading: false,
                        })
                    }
                }}
            >
                {({
                    state,
                    setState,
                    values,
                    errors,
                    handleChangeAsValue,
                }) => {
                    const { orderRequest, requestIsLoading } = state
                    return (
                        <NewOrderModal
                            impurityName={impurityName}
                            values={values}
                            errors={errors}
                            hasExceededMaxOrderRequests={state.requestCount >= MAX_ORDER_REQUESTS}
                            maxTradeLimit={maxTradeLimit}
                            minTradeLimit={minTradeLimit}
                            side={side}
                            asset={asset}
                            order={state.order}
                            orderRequest={orderRequest}
                            availableUnits={availableUnits}
                            isValidQuantity={isValidNewOrderForm(values, maxTradeLimit, minTradeLimit, values.quantityUnitCode)}
                            isAuthenticating={state.isAuthenticating}
                            isRequestLoading={requestIsLoading}
                            isOrderLoading={state.orderIsLoading}
                            orderIsSuccess={state.orderIsSuccess}
                            errorMessage={state.errorMessage}
                            orderIsFailure={state.orderIsFailure}
                            onChangeQuantity={(quantity) => {
                                handleChangeAsValue('quantity', quantity)
                            }}
                            onChangeUnit={(quantityUnitCode) => {
                                handleChangeAsValue('quantityUnitCode', quantityUnitCode)
                            }}
                            onNext={() => setState({ isAuthenticating: true })}
                            onCancelAuthenticate={() => setState({ isAuthenticating: false })}
                            onDone={onDismiss}
                            onRequestExpired={async () => {
                                setState({ requestIsLoading: true })
                                const input = orderRequestInput
                                const newRequestCount = state.requestCount + 1
                                try {
                                    const oRequest = await createOrderRequest(apollo, input)
                                    setState({
                                        orderRequest: oRequest,
                                        requestIsLoading: false,
                                        isAuthenticating: false,
                                        requestCount: newRequestCount,
                                    })
                                } catch (e) {
                                    setState({
                                        errorMessage: e.message,
                                        orderRequest: null,
                                        requestIsLoading: false,
                                        isAuthenticating: false,
                                        requestCount: newRequestCount,
                                    })
                                }
                            }}
                            onConfirmOrder={async (pin) => {
                                onToggleDisableDismiss(true)
                                setState({ orderIsLoading: true })
                                const { quantityUnitCode } = values
                                const quantity = unitToMilligram(state.values.quantity, quantityUnitCode)
                                const orderRequestId = state.orderRequest.id
                                const input = {
                                    pin,
                                    quantity,
                                    quantityUnitCode,
                                    orderRequestId,
                                }
                                try {
                                    const order = await createOrder(apollo, input)

                                    setState({
                                        order,
                                        orderIsLoading: false,
                                        orderIsSuccess: true,
                                    })
                                } catch (error) {
                                    if (error.graphQLErrors) {
                                        const errorMessage = error.graphQLErrors[0].message
                                        setState({
                                            errorMessage,
                                            orderIsFailure: true,
                                        })
                                    }
                                }
                                setState({ orderIsLoading: false })
                                onToggleDisableDismiss(false)
                            }}

                        />
                    )
                }}
            </FormState>

        </Modal>
    )
}

export default NewOrderModalWithState
