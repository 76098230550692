import React from 'react'
import State from '../../../util/State'
import AssetCard from './AssetCard'
import NewOrderModalWithState from '../new-order/NewOrderModalWithState'

const INITIAL_STATE = {
    isOpen: false,
    side: null,
    unitCode: null,
    currencyCode: null,
    initialSpotPrice: null,
    dismissIsDisabled: null,
    impurityId: null,
    impurityName: null,
}

const AssetCardWithModal = ({
    asset,
    canSell,
    canBuy,
    marketData,
    unitCode,
    currencyCode,
    currencySymbol,
    availableCurrencies,
    availableUnits,
    maxTradeLimit,
    minTradeLimit,
    onChangeUnitCode,
    onChangeCurrencyCode,
    onToggleKarats,
    isShowingKarats,
}) => (
    <State initialState={INITIAL_STATE}>
        {({ state, setState }) => (
            <>
                <AssetCard
                    marketData={marketData}
                    name={asset.name}
                    code={asset.code}
                    canSell={canSell}
                    canBuy={canBuy}
                    unitCode={unitCode}
                    currencyCode={currencyCode}
                    currencySymbol={currencySymbol}
                    availableCurrencies={availableCurrencies}
                    availableUnits={availableUnits}
                    onChangeUnitCode={onChangeUnitCode}
                    onChangeCurrencyCode={onChangeCurrencyCode}
                    onClickBuy={() => setState({
                        isOpen: true,
                        side: 'BUY',
                        initialSpotPrice: marketData.buyPrice,
                        currencyCode,
                        unitCode,
                    })}
                    onClickSell={(impurityMarketData = null) => {
                        let initialSpotPrice = marketData.sellPrice
                        let { impurityId, name } = state
                        if (impurityMarketData !== null) {
                            initialSpotPrice = impurityMarketData.sellPrice;
                            ({ impurityId, name } = impurityMarketData)
                        }
                        setState({
                            isOpen: true,
                            side: 'SELL',
                            initialSpotPrice,
                            currencyCode,
                            unitCode,
                            impurityId,
                            impurityName: name,
                        })
                    }}
                    onToggleKarats={onToggleKarats}
                    isShowingKarats={isShowingKarats}
                />
                <NewOrderModalWithState
                    isOpen={state.isOpen}
                    initialSpotPrice={state.initialSpotPrice}
                    side={state.side}
                    asset={asset}
                    currencyCode={currencyCode}
                    unitCode={unitCode}
                    orderRequest={state.orderRequest}
                    dismissIsDisabled={state.dismissIsDisabled}
                    onToggleDisableDismiss={(dismissIsDisabled) => setState({ dismissIsDisabled })}
                    onDismiss={() => setState(INITIAL_STATE)}
                    maxTradeLimit={maxTradeLimit}
                    minTradeLimit={minTradeLimit}
                    availableUnits={availableUnits}
                    impurityId={state.impurityId}
                    impurityName={state.impurityName}
                />
            </>
        )}
    </State>
)

export default AssetCardWithModal
