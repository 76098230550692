import React from 'react'
import Tag from '../../util/Tag'
import PreferredCurrencyValue from '../../util/PreferredCurrencyValue'
import PreferredUnitValue from '../../util/PreferredUnitValue'
import BalanceCardContainer from './BalanceCardContainer'

const AssetWalletCard = ({
    wallet,
}) => {
    const {
        asset,
        value,
        quantity,
    } = wallet
    const isZero = value === 0
    const isNegative = value < 0
    const isPositive = value > 0
    return (
        <BalanceCardContainer>
            <div className="flex justify-between items-end mb-6">
                <h5 className="inline text-lg">
                    {asset.name}
                    {' '}
                </h5>
                <Tag className="bg-primary text-white">{asset.code}</Tag>
            </div>
            <div className="flex justify-between items-end flex-wrap">
                <h3 className="inline font-black text-primary text-3xl">
                    <PreferredUnitValue
                        value={quantity}
                    />
                </h3>
                <h5 className="inline text-lg">
                    <PreferredCurrencyValue
                        isNegative={isNegative}
                        isPositive={isPositive}
                        value={Math.abs(value)}
                    />
                </h5>
            </div>
            <div>
                <i className="text-xs">
                    Estimated
                    {' '}
                    {(() => {
                        if (isZero) {
                            return 'value'
                        }
                        if (isPositive) {
                            return 'credit'
                        }
                        return 'debt'
                    })()}
                </i>
            </div>
        </BalanceCardContainer>
    )
}

export default AssetWalletCard
