import React from 'react'
import PageError from '../../layout/PageError'
import BalancePage from './BalancePage'
import useFetchBalancePageData from './useFetchBalancePageData'

const BalancePageWithState = () => {
    const {
        error,
        loading,
        customer,
        balance,
    } = useFetchBalancePageData()

    if (typeof error !== 'undefined') {
        return (
            <PageError
                activeMenuItem="balance"
                error={error}
            />
        )
    }

    return (
        <BalancePage
            loading={loading}
            customer={customer}
            balance={balance}
        />
    )
}

export default BalancePageWithState
