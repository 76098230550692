import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import Notifications from './util/Notifications'
import AppStateProvider from './providers/AppStateProvider'
import NotificationsProvider from './providers/NotificationsProvider'

function AppProviders({
    appState,
    apolloClient,
    notificationDispatcher,
    children,
}) {
    return (
        <BrowserRouter>
            <AppStateProvider value={appState}>
                <ApolloProvider client={apolloClient}>
                    <NotificationsProvider dispatcher={notificationDispatcher}>
                        {children}
                        <Notifications />
                    </NotificationsProvider>
                </ApolloProvider>
            </AppStateProvider>
        </BrowserRouter>
    )
}

export default AppProviders
