import { gql } from '@apollo/client'

const COMPLETE_MY_CUSTOMER_ONBOARDING_MUTATION = gql`
    mutation completeMyCustomerOnboarding($input: MyCustomerOnboardingInput!) {
        completeMyCustomerOnboarding(input: $input) {
            user {
                id
                email
                hasCompletedOnboarding
            }
        }
    }
`

export default async function completeMyCustomerOnboarding(apolloClient, input, options) {
    const mutation = COMPLETE_MY_CUSTOMER_ONBOARDING_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
