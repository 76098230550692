import React from 'react'
import Spinner from '../../../util/Spinner'
import NewOrderContainer from './NewOrderContainer'

const NewOrderLoading = () => (
    <NewOrderContainer>
        <Spinner />
        <div className="mt-8 text-center gap-y-4">
            <p>
                We are processing your order request.
            </p>
            <p>
                Please wait for confirmation.
            </p>
        </div>
    </NewOrderContainer>
)

export default NewOrderLoading
