import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import completeMyCustomerOnboarding from '../../../api/completeMyCustomerOnboarding'
import { graphqlErrorsToFormErrors } from '../../../util/graphql'
import useCompleteOnboardingForm from './useCompleteOnboardingForm'
import OnboardingPage from './OnboardingPage'

const OnboardingPageWithState = ({
    queriesToRefetch,
}) => {
    const { navigate } = useNavigate()
    const { dispatchGraphqlError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const apolloClient = useApolloClient()
    const { currentUser, refresh } = useAppState()
    const formState = useCompleteOnboardingForm(currentUser)

    return (
        <OnboardingPage
            formState={formState}
            isSubmitting={isSubmitting}
            onSubmit={async (e) => {
                e.preventDefault()
                if (formState.validate()) {
                    setIsSubmitting(true)
                    try {
                        await completeMyCustomerOnboarding(
                            apolloClient,
                            formState.valuesToInput(),
                            { refetchQueries: queriesToRefetch },
                        )
                        await refresh()
                        navigate('/')
                    } catch (error) {
                        const formErrors = graphqlErrorsToFormErrors(error.graphQLErrors)
                        if (formErrors.length !== 0) {
                            formState.updateErrors(formErrors)
                        } else {
                            dispatchGraphqlError(error)
                        }
                        setIsSubmitting(false)
                    }
                }
            }}
        />
    )
}

export default OnboardingPageWithState
