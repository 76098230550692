import React from 'react'
import Logo from '../util/Logo'
import MainCentered from '../layout/MainCentered'

const AppUnavailable = () => (
    <MainCentered>
        <Logo height={40} className="mb-4" />
        <h3 className="font-black text-3xl text-primary">App Unavailable</h3>
        <p className="text-center">
            Value Trading is currently not available.
            <br />Please try again later.
        </p>
    </MainCentered>
)

export default AppUnavailable
