import roundTo from 'round-to'
import * as constants from './constants'

const {
    UNIT_CODE_GRAM: G,
    UNIT_CODE_OUNCE: OZ,
    UNIT_CODE_KILOGRAM: KG,
    GRAM_ROUND,
    OUNCE_ROUND,
    KILOGRAM_ROUND,
} = constants

const OUNCE_TO_GRAM_RATIO = 31.1034768

export const ounceToGram = (value) => (
    value * OUNCE_TO_GRAM_RATIO
)

export const gramToOunce = (value) => (
    value / OUNCE_TO_GRAM_RATIO
)

export const gramToMilligram = (value) => (
    value * 1000
)

export const gramToKilogram = (value) => (
    value / 1000
)

export const kilogramToMilligram = (value) => (
    value * 1000 * 1000
)

export const milligramToGram = (value) => (
    value / 1000
)

export const milligramToKilogram = (value) => (
    value / 1000 / 1000
)

export const ounceToMilligram = (value) => (
    ounceToGram(value) * 1000
)

export const milligramToOunce = (value) => (
    gramToOunce(value / 1000)
)

export const ounceToKilogram = (value) => (
    ounceToGram(value) / 1000
)

export const kilogramToOunce = (value) => (
    gramToOunce(value * 1000)
)

export const unitToMilligram = (value, unitCode) => {
    let result = value
    if (unitCode === G) {
        result = gramToMilligram(value)
    }
    if (unitCode === KG) {
        result = kilogramToMilligram(value)
    }
    if (unitCode === OZ) {
        result = ounceToMilligram(value)
    }
    return roundTo(result, 0)
}

export const milligramToUnit = (value, unitCode) => {
    let result = value
    if (unitCode === G) {
        result = milligramToGram(value)
    }
    if (unitCode === OZ) {
        result = milligramToOunce(value)
    }
    if (unitCode === KG) {
        result = milligramToKilogram(value)
    }
    return result
}

export const milligramToUnitUsingOrderRounding = (value, unitCode) => {
    let rounding = 0 // For MG -> MG
    if (unitCode === G) {
        rounding = GRAM_ROUND
    }
    if (unitCode === OZ) {
        rounding = OUNCE_ROUND
    }
    if (unitCode === KG) {
        rounding = KILOGRAM_ROUND
    }
    return roundTo(milligramToUnit(value, unitCode), rounding)
}

export const getFormattedMinimum = (minimum, unitCode) => {
    if (unitCode === KG) {
        // Smallest amount to trade is grams, so first round up minimum in grams
        const minimumInGram = milligramToUnit(minimum, G)
        const roundedMinimumInGram = roundTo.up(minimumInGram, 0)
        const minimumInKilogram = gramToKilogram(roundedMinimumInGram)
        return roundTo.up(minimumInKilogram, 3)
    }
    return roundTo.up(milligramToUnit(minimum, unitCode), 0)
}

export const getInitalQuantity = (minimum, unitCode) => roundTo.up(milligramToUnit(minimum, unitCode), 0)

export const getFormattedMaximum = (maximum, unitCode) => {
    if (unitCode === KG) {
        // Smallest amount to trade is grams, so first round down maximum in grams
        const maximumInGram = milligramToUnit(maximum, G)
        const roundedmaximumInGram = roundTo.down(maximumInGram, 0)
        const maximumInKilogram = gramToKilogram(roundedmaximumInGram)
        return roundTo.down(maximumInKilogram, 3)
    }
    return roundTo.down(milligramToUnit(maximum, unitCode), 0)
}
