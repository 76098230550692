import React, { useEffect } from 'react'
import useAppState from '../../hooks/useAppState'
import PageError from '../../layout/PageError'
import useModalState from '../../hooks/useModalState'
import HistoryPage from './HistoryPage'
import useFetchHistoryPageData from './useFetchHistoryPageData'
import UpdateOrderModalWithState from './UpdateOrderModalWithState'

const HistoryPageWithState = ({
    first = 10,
}) => {
    const updateOrderModalState = useModalState()
    const {
        error,
        hasError,
        isFetching,
        orders,
        refetch,
        isFetchingMore,
        hasNextPage,
        handleFetchMoreOrders,
    } = useFetchHistoryPageData(first)
    const { currentUser } = useAppState()

    useEffect(() => refetch(), [])

    if (hasError) {
        return (
            <PageError
                activeMenuItem="orders"
                error={error}
            />
        )
    }

    return (
        <>
            <HistoryPage
                currentUser={currentUser}
                hasError={hasError}
                error={error}
                orders={orders}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasNextPage={hasNextPage}
                handleFetchMoreOrders={handleFetchMoreOrders}
                onEditOrder={(order) => updateOrderModalState.open({ order })}
            />
            {updateOrderModalState.isOpen && (
                <UpdateOrderModalWithState
                    isVisible={updateOrderModalState.isOpen}
                    onCloseModal={() => updateOrderModalState.close()}
                    onUpdateOrderCompleted={() => updateOrderModalState.close()}
                    {...updateOrderModalState.initialState}
                />
            )}
        </>
    )
}

export default HistoryPageWithState
