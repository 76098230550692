import useFormState, { createFormValidation } from 'use-form-state'
import {
    isValidPin,
    isPinLongEnough,
    isValidRepeatPin,
} from '../../../../validation/user'

const newPinToFormValues = () => ({
    oldPin: '',
    newPin: '',
    repeatPin: '',
})

const validation = createFormValidation([{
    path: 'oldPin',
    validate: (oldPin) => isValidPin(oldPin),
    message: 'Please fill in your old pin code.',
}, {
    path: 'newPin',
    validate: (newPin) => isValidPin(newPin) && isPinLongEnough(newPin),
    message: 'Please fill in a valid new pin.',
}, {
    path: 'repeatPin',
    validate: (repeatPin, { newPin }) => isValidRepeatPin(newPin, repeatPin),
    message: 'Please fill in the same pin.',
}])

const toNewPinInput = ({
    oldPin,
    newPin,
}) => ({
    oldPin,
    newPin,
})

const useUpdatePinForm = (options) => (
    useFormState(
        newPinToFormValues(),
        {
            validation,
            valuesToInput: toNewPinInput,
            ...options,
        },
    )
)

export default useUpdatePinForm
