export const home = () => '/'

export const login = () => '/login'

export const loginPhone = () => '/login/phone'

export const loginEmail = () => '/login/email'

export const loginPassword = () => '/login/password'

export const balance = () => '/balance'

export const trade = () => '/trade'

export const history = () => '/history'

export const settings = () => '/settings'
