import React from 'react'
import clsx from 'clsx'

const Alert = ({
    className,
    children,
    isBlue,
    isGreen,
    isYellow,
    isRed,
}) => (
    <div className={
        clsx(
            'relative py-3 px-4 rounded-md mb-4 border-1',
            isRed && 'text-red-500 border-red-500 bg-red-50',
            isBlue && 'text-blue-500 border-blue-500 bg-blue-50',
            isGreen && 'text-green-500 border-green-500 bg-green-50',
            isYellow && 'text-yellow-500 border-yellow-500 bg-yellow-50',
            className,
        )
    }
    >
        {children}
    </div>
)

export default Alert
