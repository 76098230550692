import React from 'react'
import Button from '../../util/Button'

const LogoutButton = ({
    isLoggingOut,
    onLogout,
}) => (
    <Button
        className="w-full btn-secondary text-red-500"
        disabled={isLoggingOut}
        onClick={onLogout}
    >
        {isLoggingOut ? 'Logging out...' : 'Logout'}
    </Button>
)

export default LogoutButton
