import React from 'react'
import FeatherIcon from '../../util/FeatherIcon'
import CurrencyValue from '../../util/CurrencyValue'
import BalanceCardContainer from './BalanceCardContainer'

const MoneyWalletCard = ({
    wallet,
}) => {
    const {
        value,
        currencyCode,
    } = wallet
    return (
        <BalanceCardContainer>
            <div className="flex mb-4">
                <FeatherIcon
                    name="wallet"
                    size="24"
                    className="mr-4"
                />
                <h5 className="inline text-lg">{currencyCode}</h5>
            </div>
            <h3 className="inline font-black text-primary text-3xl">
                <CurrencyValue
                    isNegative={value < 0}
                    isPositive={value > 0}
                    currencyCode={currencyCode}
                    value={Math.abs(value)}
                />
            </h3>
        </BalanceCardContainer>
    )
}

export default MoneyWalletCard
