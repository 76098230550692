import React from 'react'
import { Dialog } from '@reach/dialog'
import FeatherIcon from './FeatherIcon'

const Modal = ({
    onDismiss,
    dismissIsDisabled,
    children,
    ...props
}) => (
    <Dialog
        {...props}
        onDismiss={() => {
            if (!dismissIsDisabled) {
                onDismiss()
            }
        }}
    >
        <button
            type="button"
            disabled={dismissIsDisabled}
            className="btn-icon absolute right-0 top-0 border-transparent mr-3 mt-3"
            onClick={onDismiss}
        >
            <FeatherIcon name="cross" />
        </button>
        {children}
    </Dialog>
)

export default Modal
