import React from 'react'
import { getFormattedMinimum, getFormattedMaximum } from '../../../../util/units'

const TradeLimits = ({
    max,
    min,
    unitCode,
}) => (
    <div className="flex flex-col text-xs">
        <div className="flex justify-between pr-12">
            <span>Minimum:</span><span>{getFormattedMinimum(min, unitCode)} {unitCode}</span>
        </div>
        <div className="flex justify-between pr-12">
            <span>Maximum:</span><span>{getFormattedMaximum(max, unitCode)} {unitCode}</span>
        </div>
    </div>
)

export default TradeLimits
