import React from 'react'
import PinInput from 'react-pin-input'
import NewOrderContainer from './NewOrderContainer'

const NewOrderAuthenticate = ({
    onComplete,
    onCancel,
}) => (
    <NewOrderContainer>
        <p>Please enter your personal pin.</p>
        <PinInput
            length={4}
            focus
            secret
            inputStyle={{
                borderRadius: '50%',
            }}
            onComplete={onComplete}
        />
        <button
            type="button"
            className="btn-secondary mt-8"
            onClick={onCancel}
        >
            Cancel
        </button>
    </NewOrderContainer>
)

export default NewOrderAuthenticate
