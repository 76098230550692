import React from 'react'
import Main from './Main'
import Navbar from './Navbar'

const PageError = ({
    activeMenuItem,
    error,
}) => (
    <>
        <Navbar activeMenuItem={activeMenuItem} />
        <Main>
            <h3 className="font-black text-3xl text-primary">Failed to load page. The following error occured:</h3>
            <p>{error}</p>
        </Main>
    </>
)

export default PageError
