import React from 'react'
import * as theme from '../../styles/theme'

const HEIGHT = 4
const WIDTH = 100

const ProgressBar = ({
    percentageRatio,
    transitionSpeed = 0.3,
}) => {
    const percentage = WIDTH * percentageRatio
    const lineOffset = WIDTH - percentage
    return (
        <svg width="100%" height="4" viewBox={`0 0 ${WIDTH} ${HEIGHT}`} preserveAspectRatio="none">
            <line
                x1="0"
                y1="50%"
                x2={WIDTH}
                y2={HEIGHT / 2}
                strokeWidth={HEIGHT}
                stroke={theme.defaultBorderColor}
            />
            <line
                x1="0"
                y1={HEIGHT / 2}
                x2={WIDTH}
                y2={HEIGHT / 2}
                strokeWidth={HEIGHT}
                stroke={theme.colorPrimary}
                strokeDasharray={WIDTH}
                strokeDashoffset={lineOffset}
                className="progressLine"
                style={{
                    transition: `stroke-dashoffset ${transitionSpeed}s linear`,
                }}
            />
        </svg>
    )
}

export default ProgressBar
