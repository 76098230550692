import React, { Component } from 'react'
import roundTo from 'round-to'
import ProgressBar from '../../../util/ProgressBar'

const MAX_SECONDS = 30
const MAX_TIME = 1000 * MAX_SECONDS

export const getRemainingSeconds = (requestedAt) => {
    const countDownTo = new Date(requestedAt).getTime()
    return roundTo((countDownTo + MAX_TIME - Date.now()) / 1000, 0)
}

class TimeRemaining extends Component {
    constructor(props) {
        super(props)

        this.countdownFrom = null
        this.state = {
            secondsRemaining: MAX_SECONDS,
        }
    }

    componentDidMount() {
        this.startTimer()
    }

    componentWillUnmount() {
        this.stopTimer()
    }

    startTimer() {
        this.countdownFrom = MAX_SECONDS
        const timer = setInterval(() => {
            this.setState({ secondsRemaining: this.countdownFrom })
            if (this.countdownFrom === 0) {
                // We wait 1 second te call function
                setTimeout(() => this.props.onRequestExpired(), 1000)
                clearInterval(timer)
            }
            this.countdownFrom -= 1
        }, 1000)
        this.timer = timer

        return timer
    }

    stopTimer() {
        clearInterval(this.timer)
        this.countDownTo = null
    }

    render() {
        const { secondsRemaining } = this.state
        return (
            <div className="flex flex-col mb-4">
                <div className="self-end text-sm font-light">{secondsRemaining}s remaining</div>
                <ProgressBar
                    percentageRatio={(secondsRemaining / MAX_SECONDS)}
                    transitionSpeed={1}
                />
            </div>
        )
    }
}

export default TimeRemaining
