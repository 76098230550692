import React from 'react'
import { milligramToUnitUsingOrderRounding } from '../../util/units'

const UnitValue = ({
    value,
    unitCode,
}) => (
    <>
        {milligramToUnitUsingOrderRounding(value, unitCode)}
        {unitCode}
    </>
)

export default UnitValue
