import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import clsx from 'clsx'

const AnimatedMessage = ({ children }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        {children}
    </motion.div>
)

function InputGroup({
    className,
    label,
    error,
    children,
}) {
    const hasLabel = typeof label !== 'undefined'
    const hasError = typeof error !== 'undefined' && error !== null && error.length > 0

    return (
        <div className={
            clsx(
                'mb-4',
                hasError && 'text-red-500',
                className,
            )
        }
        >
            {hasLabel && (
                <label>{label}</label>
            )}
            {children}
            <AnimatePresence>
                {hasError && error.map((e, index) => {
                    if (e !== null) {
                        return (
                            <AnimatedMessage key={index}>
                                <small className="block italic">
                                    {e}
                                </small>
                            </AnimatedMessage>
                        )
                    }
                    return e
                })}
            </AnimatePresence>
        </div>
    )
}

export default InputGroup