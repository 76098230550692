import React from 'react'

const Page = ({
    children,
}) => (
    <div className="mb-[120px]">
        {children}
    </div>
)

export default Page
