import React from 'react'
import FeatherIcon from '../../util/FeatherIcon'

const STATUS_DELIVERED = 'DELIVERED'
const STATUS_PARTIALY_DELIVERED = 'PARTIALY_DELIVERED'
const STATUS_PICKED_UP = 'PICKED_UP'

const OrderStatus = ({
    status,
}) => {
    if (status === STATUS_DELIVERED || status === STATUS_PICKED_UP) {
        return (
            <FeatherIcon>
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                <polyline className="text-green-500" points="22 4 12 14.01 9 11.01" />
            </FeatherIcon>
        )
    }
    if (status === STATUS_PARTIALY_DELIVERED) {
        return (
            <FeatherIcon>
                <circle cx="12" cy="12" r="10" />
                <line className="text-red-500" x1="8" y1="12" x2="16" y2="12" />
            </FeatherIcon>
        )
    }
    return (
        <FeatherIcon>
            <circle cx="12" cy="12" r="10" />
        </FeatherIcon>
    )
}

export default OrderStatus
