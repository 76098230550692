import React from 'react'
import Navbar from '../../layout/Navbar'
import Main from '../../layout/Main'
import PageTitle from '../../layout/PageTitle'
import OrderList from './OrderList'

const HistoryPage = ({
    currentUser,
    orders,
    isFetching,
    isFetchingMore,
    hasNextPage,
    handleFetchMoreOrders,
    onEditOrder,
}) => (
    <>
        <Navbar activeMenuItem="orders" />
        <Main>
            <PageTitle
                title="Recent Transaction"
                text="Below you find a list of your company's most recent transactions."
            />
            <OrderList
                currentUser={currentUser}
                orders={orders}
                isFetching={isFetching}
                isFetchingMore={isFetchingMore}
                hasNextPage={hasNextPage}
                onFetchMore={handleFetchMoreOrders}
                onEditOrder={onEditOrder}
            />
        </Main>
    </>
)

export default HistoryPage
