import React from 'react'
import clsx from 'clsx'
import Icon from './FeatherIcon'

const DropdownMenuItem = ({
    iconName,
    className,
    disabled = false,
    children,
    ...props
}) => (
    <button
        disabled={disabled}
        type="button"
        role="menuitem"
        className={(clsx(
            'flex items-center w-full px-4 py-2 text-sm focus:outline-none border-none text-silver font-light',
            className,
        ))}
        {...props}
    >
        {iconName && (
            <Icon
                name={iconName}
                size={16}
                className="mr-4"
            />
        )}
        {children}
    </button>
)

export default DropdownMenuItem
