import React from 'react'

const Check = () => (
    <polyline points="20 6 9 17 4 12" />
)

const Comment = () => (
    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
)

const Cross = () => (
    <>
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </>
)

const Edit = () => (
    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
)

const FileText = () => (
    <>
        <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
        <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" />
    </>
)

const User = () => (
    <>
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
    </>
)

const Loader = () => (
    <>
        <line x1="12" y1="2" x2="12" y2="6" />
        <line x1="12" y1="18" x2="12" y2="22" />
        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
        <line x1="2" y1="12" x2="6" y2="12" />
        <line x1="18" y1="12" x2="22" y2="12" />
        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
        <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
    </>
)

const TrendingUp = () => (
    <>
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
        <polyline points="17 6 23 6 23 12" />
    </>
)

const AlertTriangle = () => (
    <>
        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
        <line x1="12" y1="9" x2="12" y2="13" />
        <line x1="12" y1="17" x2="12" y2="17" />
    </>
)

const PauseCircle = ({
    innerColor,
}) => (
    <>
        <circle cx="12" cy="12" r="10" />
        <line stroke={innerColor} x1="10" y1="15" x2="10" y2="9" />
        <line stroke={innerColor} x1="14" y1="15" x2="14" y2="9" />
    </>
)

const CrossCircle = ({
    innerColor,
}) => (
    <>
        <circle cx="12" cy="12" r="10" />
        <line stroke={innerColor} x1="15" y1="9" x2="9" y2="15" />
        <line stroke={innerColor} x1="9" y1="9" x2="15" y2="15" />
    </>
)

const Pdf = () => (
    <>
        <polyline fill="none" points="20,1 20,9 28,9 " stroke="#111111" strokeLinecap="butt" />
        <polyline fill="none" points="28,13 28,9 20,1 4,1 4,13 " stroke="#111111" />
        <polyline fill="none" points="4,27 4,31 28,31 28,27 " stroke="#111111" />
        <path d="M13.143,19.105c0,0.638-0.188,1.131-0.564,1.479s-0.911,0.521-1.604,0.521h-0.434 V23H8.998v-5.711h1.977c0.721,0,1.263,0.157,1.625,0.473S13.143,18.524,13.143,19.105z M10.541,19.848h0.281 c0.232,0,0.416-0.065,0.553-0.195s0.205-0.31,0.205-0.539c0-0.386-0.213-0.578-0.641-0.578h-0.398V19.848z" stroke="none" strokeLinecap="square" />
        <path d="M18.9,20.023c0,0.953-0.262,1.688-0.787,2.203S16.851,23,15.9,23h-1.848v-5.711 h1.977c0.917,0,1.625,0.234,2.123,0.703S18.9,19.138,18.9,20.023z M17.299,20.078c0-0.523-0.104-0.911-0.311-1.164 s-0.521-0.379-0.943-0.379h-0.449v3.199h0.344c0.469,0,0.812-0.136,1.031-0.408S17.299,20.638,17.299,20.078z" stroke="none" strokeLinecap="square" />
        <path d="M21.443,23h-1.52v-5.711h3.375v1.238h-1.855v1.09h1.711v1.238h-1.711V23z" stroke="none" strokeLinecap="square" />
        <rect height="14" width="30" fill="none" stroke="#111111" x="1" y="13" />
    </>
)

const MoreVertical = () => (
    <>
        <circle cx="12" cy="12" r="1" />
        <circle cx="12" cy="5" r="1" />
        <circle cx="12" cy="19" r="1" />
    </>
)

// Self made
const Wallet = () => (
    <>
        <path d="M3,3 L14,3 C15.1045695,3 16,3.8954305 16,5 L16,6 L1,6 L1,5 C1,3.8954305 1.8954305,3 3,3 Z" />
        <path d="M19,11 L17,11 C15.8954305,11 15,11.8954305 15,13 C15,14.1045695 15.8954305,15 17,15 L19,15 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,6 L17,6 C18.1045695,6 19,6.8954305 19,8 L19,11 Z" />
        <path d="M17,11 L19,11 C20.1045695,11 21,11.8954305 21,13 C21,14.1045695 20.1045695,15 19,15 L17,15 C15.8954305,15 15,14.1045695 15,13 C15,11.8954305 15.8954305,11 17,11 Z" />
    </>
)

const FeatherIcon = ({
    className,
    style,
    size = 24,
    name,
    color = 'currentColor',
    innerColor = 'currentColor',
    viewBox = '0 0 24 24',
    children,
}) => (
    <svg
        style={style}
        width={size}
        height={size}
        viewBox={viewBox}
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
    >
        {(() => {
            switch (name) {
                case 'check':
                    return <Check />
                case 'comment':
                    return <Comment />
                case 'cross':
                    return <Cross />
                case 'edit':
                    return <Edit />
                case 'fileText':
                    return <FileText />
                case 'user':
                    return <User />
                case 'loader':
                    return <Loader />
                case 'trendingUp':
                    return <TrendingUp />
                case 'alertTriangle':
                    return <AlertTriangle />
                case 'pauseCircle':
                    return <PauseCircle innerColor={innerColor} />
                case 'crossCircle':
                    return <CrossCircle innerColor={innerColor} />
                case 'wallet':
                    return <Wallet />
                case 'pdf':
                    return <Pdf />
                case 'more':
                    return <MoreVertical />
                default:
                    return null
            }
        })()}
        {children}
    </svg>
)

export default FeatherIcon
