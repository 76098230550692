import { createValidationErrorObject } from '../index'
import { isValidQuantity } from './newOrder'

export const getQuantityError = createValidationErrorObject(
    isValidQuantity,
    'quantity',
    'Please provide a valid amount.',
)

export const getNewOrderFormErrors = (values) => ({
    ...getQuantityError(values.quantity, values.maxTradeLimit, values.minTradeLimit, values.unitCode),
})
