import { gql } from '@apollo/client'

const UPDATE_MY_CUSTOMER_PROFILE_MUTATION = gql`
    mutation updateMyCustomerProfile($input: MyCustomerProfileInput!) {
        updateMyCustomerProfile(input: $input) {
            user {
                id
                firstName
                lastName
                email
                phone
                preferredUnitCode
                preferredCurrencyCode
            }
        }
    }
`

export default async function updateMyCustomer(apolloClient, input, options) {
    const mutation = UPDATE_MY_CUSTOMER_PROFILE_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
