import createApolloClient from './createApolloClient'

let apolloClient = null

export default function getApolloClient() {
    if (apolloClient === null) {
        apolloClient = createApolloClient()
    }
    return apolloClient
}
