import React from 'react'

const Up = () => <path d="M0 10 L16 10 L8 0 L0 10" />
const Down = () => <path d="M0 0 L16 0 L8 10 L0 0" />
const Left = () => <path d="M0 0 L16 0 L8 10 L0 0" />
const Right = () => <path d="M0 0 L16 0 L8 10 L0 0" />

const Triangle = ({
    direction = 'down',
    color = 'currentColor',
    size = 10,
}) => (
    <svg width={`${size}px`} viewBox="0 0 16 10" fill={color}>
        {(() => {
            switch (direction) {
                case 'up':
                    return <Up />
                case 'down':
                    return <Down />
                case 'left':
                    return <Left />
                case 'right':
                    return <Right />
                default:
                    return null
            }
        })()}
    </svg>
)

export default Triangle
