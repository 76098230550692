import React from 'react'
import InputGroup from '../../../util/InputGroup'
import Button from '../../../util/Button'
import FormPinInput from '../../../util/FormPinInput'

const NewPinForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => {
    const {
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <form className="form" onSubmit={onSubmit}>
            <InputGroup
                error={getErrorMessages('oldPin')}
                label="Old pin code"
            >
                <FormPinInput
                    focus
                    {...getInputProps('oldPin')}
                />
            </InputGroup>
            <div className="mb-4">
                <InputGroup
                    error={getErrorMessages('newPin')}
                    label="New pin code"
                >
                    <FormPinInput
                        {...getInputProps('newPin')}
                    />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    error={getErrorMessages('repeatPin')}
                    label="Repeat new pin code"
                >
                    <FormPinInput
                        {...getInputProps('repeatPin')}
                    />
                </InputGroup>
            </div>
            <Button
                type="submit"
                className="btn-primary w-full"
                loading={isSubmitting}
                onClick={onSubmit}
            >
                Save
            </Button>
        </form>
    )
}

export default NewPinForm
