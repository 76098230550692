import React from 'react'

const NewOrderContainer = ({
    children,
}) => (
    <div className="flex flex-col items-center justify-center h-[334px]">
        {children}
    </div>
)

export default NewOrderContainer
