import React from 'react'
import PreferredCurrencyValue from '../../util/PreferredCurrencyValue'
import BalanceCardContainer from './BalanceCardContainer'

const BalanceCard = ({
    balance,
    hasTradeLimit,
    fixedTradeLimit,
}) => {
    const {
        total,
        tradeLimit,
        tradeMargin,
    } = balance

    let limitText = 'None'
    let totalLimitText = 'None'
    if (hasTradeLimit) {
        if (tradeMargin !== null) {
            limitText = `${tradeMargin}%`
            totalLimitText = tradeLimit
        }
        if (fixedTradeLimit !== null) {
            limitText = 'Fixed'
            totalLimitText = fixedTradeLimit
        }
    }

    return (
        <BalanceCardContainer>
            <div className="text-center mb-6">
                <h2 className="text-4xl text-primary font-black">
                    <PreferredCurrencyValue
                        isNegative={total < 0}
                        isPositive={total > 0}
                        value={Math.abs(total)}
                    />
                </h2>
            </div>
            <div>
                <div className="flex">
                    {hasTradeLimit
                        ? (
                            <>
                                <div className="flex-1">
                                    <div>Trade limit</div>
                                    <h5>
                                        {limitText}
                                    </h5>
                                </div>
                                <div className="flex-1">
                                    <div>Total limit</div>
                                    <h5>
                                        <PreferredCurrencyValue
                                            value={totalLimitText}
                                        />
                                    </h5>
                                </div>
                            </>
                        )
                        : (
                            <>
                                <div className="flex-1">
                                    <div>{limitText}</div>
                                </div>
                                <div className="flex-1">
                                    <div>{totalLimitText}</div>
                                </div>
                            </>
                        )}
                </div>
            </div>
        </BalanceCardContainer>
    )
}

export default BalanceCard
