import React from 'react'
import MainCentered from '../../layout/MainCentered'
import UnauthorizedFormWrapper from '../../layout/UnauthorizedFormWrapper'
import LoginEmailForm from './LoginEmailForm'

const LoginEmailPage = ({
    formState,
    isSubmitting,
    onSubmit,
}) => (
    <MainCentered>
        <UnauthorizedFormWrapper>
            <div className="text-center">
                <h3>Login Email</h3>
                <p>
                    We will send you a one time password for you to login with. Please provide your email address.
                </p>
            </div>
            <LoginEmailForm
                formState={formState}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
            />
        </UnauthorizedFormWrapper>
    </MainCentered>
)

export default LoginEmailPage
