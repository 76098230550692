import React, { useEffect, useState, useContext } from 'react'
import { NotificationsContext } from '../providers/NotificationsProvider'
import * as constants from '../../util/constants'

const {
    NOTIFICATION_TYPE_ERROR: ERROR,
    NOTIFICATION_TYPE_SUCCESS: SUCCESS,
    NOTIFICATION_TYPE_WARNING: WARNING,
} = constants

const useNotifications = () => {
    const notificationDispatcher = useContext(NotificationsContext)
    const [notifications, setNotifications] = useState([])
    useEffect(() => (
        notificationDispatcher.subscribe((newNotifications) => {
            setNotifications(newNotifications)
        })
    ), [notificationDispatcher])

    const dispatchSuccess = (data) => (
        notificationDispatcher.dispatch({
            type: SUCCESS,
            title: 'Success',
            message: 'Your request was handled successfully.',
            ...data,
        })
    )

    const dispatchError = (data = {}) => (
        notificationDispatcher.dispatch({
            type: ERROR,
            title: 'Error',
            message: 'Oops, looks like something went wrong, please try again later.',
            ...data,
        })
    )

    const dispatchGraphqlError = (error, data) => {
        let message = 'Oops, looks like something went wrong, please try again later.'
        if (error.networkError) {
            message = error.networkError.result.errors[0].message
        }
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            message = error.graphQLErrors[0].message
        }
        return (
            notificationDispatcher.dispatch({
                type: ERROR,
                title: 'Error',
                message,
                ...data,
            })
        )
    }

    const dispatchWarning = (data) => (
        notificationDispatcher.dispatch({
            type: WARNING,
            title: 'Warning',
            message: 'Careful, a warning was triggered.',
            ...data,
        })
    )

    const removeNotification = (notification) => {
        notificationDispatcher.removeNotification(notification)
    }

    return {
        notifications,
        dispatchError,
        dispatchWarning,
        dispatchSuccess,
        dispatchGraphqlError,
        dispatch: (data) => notificationDispatcher.dispatch(data),
        removeNotification,
    }
}

export default useNotifications
