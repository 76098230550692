import * as Sentry from '@sentry/react'

const DSN = 'https://2187e51d425040ed8cdc5c28bb9cddc4@o77097.ingest.sentry.io/1366524'

export default function initializeSentry() {
    const { REACT_APP_ENV } = window.env
    if (REACT_APP_ENV !== 'local') {
        Sentry.init({
            dsn: DSN,
            environment: REACT_APP_ENV,
            integrations: [
                Sentry.browserTracingIntegration()
            ],
        })
    }
}
