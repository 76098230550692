import React from 'react'
import * as Sentry from '@sentry/react'
import AppFailure from './AppFailure'

const AppErrorBoundary = ({
    children,
}) => (
    <Sentry.ErrorBoundary
        showDialog
        fallback={<AppFailure />}
    >
        {children}
    </Sentry.ErrorBoundary>
)

export default AppErrorBoundary
