export const currencyCodeToSymbol = (currencyCode) => {
    if (currencyCode === 'EUR') {
        return '€'
    }
    if (currencyCode === 'USD') {
        return '$'
    }
    return '?'
}

export const roundForApi = (value) => parseInt(Math.round(value * 100), 10)

export const formatCurrency = (amount, currencyCode) => {
    if (amount === null) {
        return 'undefined'
    }
    const valueStringArray = amount.toString().split('').reverse()
    const primarySeparator = currencyCode === 'EUR' ? ',' : '.'
    const secondarySeparator = currencyCode === 'EUR' ? '.' : ','
    return valueStringArray.reduce((previousValueString, currentChar, index) => {
        if (index === 1) {
            if (valueStringArray.length <= 2) {
                return `0${primarySeparator}${currentChar}${previousValueString}`
            }
            return primarySeparator + currentChar + previousValueString
        }
        if (index > 2 && (index - 2) % 3 === 0) {
            return currentChar + secondarySeparator + previousValueString
        }
        return currentChar + previousValueString
    }, '')
}
