import useFormState, { createFormValidation } from 'use-form-state'
import { isValidEmail } from '../../../validation/user'

const emailRequestToFormValues = () => ({
    email: '',
})

const validation = createFormValidation([{
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: 'Please fill in a valid email.',
}])

const useLoginEmailForm = (options) => (
    useFormState(
        emailRequestToFormValues(),
        {
            validation,
            ...options,
        },
    )
)

export default useLoginEmailForm
