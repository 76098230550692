import { INTERCOM_APP_ID } from './initializeIntercom'

export const scopeIntercomUser = (user) => {
    if (window.Intercom) {
        const {
            id,
            email,
            firstName,
            lastName,
        } = user
        const userName = `${firstName} ${lastName}`

        window.Intercom('shutdown')
        window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            user_id: id,
            email,
            name: userName,
        })
    }
}

export const updateIntercomUser = (user) => {
    if (window.Intercom) {
        const {
            id,
            email,
            firstName,
            lastName,
        } = user
        const userName = `${firstName} ${lastName}`

        window.Intercom('update', {
            app_id: INTERCOM_APP_ID,
            user_id: id,
            email,
            name: userName,
        })
    }
}

export const removeIntercomUser = () => {
    if (window.Intercom) {
        window.Intercom('shutdown')
        window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
        })
    }
}
