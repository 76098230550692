import React from 'react'
import * as theme from '../../../../styles/theme'
import AssetCardPlaceholder from './AssetCardPlaceholder'

const AssetCardSuspended = ({
    assetName,
}) => (
    <AssetCardPlaceholder
        assetName={assetName}
        title="Suspended"
        text={<>All trades are suspended for this asset. Please do try again later.</>}
        iconName="pauseCircle"
        iconInnerColor={theme.colorBlue}
    />
)

export default AssetCardSuspended
