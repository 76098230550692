import React from 'react'
import { motion } from 'framer-motion'
import FeatherIcon from './FeatherIcon'

export default function Button({
    disabled,
    loading,
    children,
    ...props
}) {
    return (
        <motion.button
            whileTap={{ scale: 0.975 }}
            disabled={disabled || loading}
            style={{ scale: 1 }}
            {...props}
        >
            {loading
                ? <FeatherIcon name="loader" className="rotating" />
                : children}
        </motion.button>
    )
}
