import React from 'react'

const Main = ({
    children,
}) => (
    <main className="mt-[78px]">
        {children}
    </main>
)

export default Main
