import { gql } from '@apollo/client'
import { DEFAULT_USER_FRAGMENT } from './getInitialData'

const LOGIN_FOR_PHONE_MUTATION = gql`
    mutation loginWithOneTimePasswordForPhone($input: LoginWithOneTimePasswordForPhoneInput!) {
        loginWithOneTimePasswordForPhone(input: $input) {
            accessToken
            refreshToken
            me {
                ...DefaultUser
            }
        }
    }
    ${DEFAULT_USER_FRAGMENT}
`

export default async function loginWithPassForPhone(apolloClient, input, options) {
    const mutation = LOGIN_FOR_PHONE_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })

    return response
}
