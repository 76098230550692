const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/

export const isValue = (value) => (
    typeof value !== 'undefined'
    && value !== null
    && value !== ''
)

export const isNumber = (value) => typeof value === 'number'

export const isGreaterThan = (max) => (value) => value > max

export const isGreaterOrEqualThan = (max) => (value) => value >= max

export const isLessThan = (max) => (value) => value < max

export const isLessOrEqualThan = (max) => (value) => value <= max

export const isEmail = (value) => emailRegex.test(value)

export const isPhone = (value) => phoneRegex.test(value)

export const isLongerThan = (max) => (value) => value.length > max

export const isLongerOrEqualThan = (max) => (value) => value.length >= max

export const isShorterThan = (max) => (value) => value.length < max

export const isShorterOrEqualThan = (max) => (value) => value.length <= max

export const isEqual = (value1, value2) => value1 === value2

export const createValidationErrorObject = (validator, key, message) => (...value) => (!validator(...value)
    ? { [key]: message }
    : { [key]: null })
