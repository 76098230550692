import React from 'react'
import InputGroup from '../../util/InputGroup'
import PhoneInput from '../../util/PhoneInput'
import FocusDidMount from '../../util/FocusDidMount'
import Input from '../../util/Input'
import UnitsSelect from './UnitsSelect'
import CurrenciesSelect from './CurrenciesSelect'

const SettingsFormFields = ({
    formState,
    focusFirstInputOnMount,
}) => {
    const {
        getNativeInputProps,
        getInputProps,
        getErrorMessages,
    } = formState

    return (
        <>
            <div className="mb-4">
                <FocusDidMount>
                    {(ref) => (
                        <InputGroup
                            error={getErrorMessages('firstName')}
                            label="First Name"
                        >
                            <Input
                                {...getNativeInputProps('firstName')}
                                reference={focusFirstInputOnMount ? ref : null}
                            />
                        </InputGroup>
                    )}
                </FocusDidMount>
            </div>
            <div className="mb-4">
                <InputGroup
                    error={getErrorMessages('lastName')}
                    label="Last Name"
                >
                    <Input {...getNativeInputProps('lastName')} />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    error={getErrorMessages('email')}
                    label="Email"
                >
                    <Input
                        {...getNativeInputProps('email')}
                        type="email"
                    />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    error={getErrorMessages('phone')}
                    label="Phone"
                >
                    <PhoneInput
                        {...getInputProps('phone')}
                        placeholder="Phone number"
                    />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    label="Unit"
                    error={getErrorMessages('preferredUnitCode')}
                >
                    <UnitsSelect
                        {...getNativeInputProps('preferredUnitCode')}
                    />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    label="Currency"
                    error={getErrorMessages('preferredCurrencyCode')}
                >
                    <CurrenciesSelect
                        {...getNativeInputProps('preferredCurrencyCode')}
                    />
                </InputGroup>
            </div>
        </>
    )
}

export default SettingsFormFields
