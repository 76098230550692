import { gql } from '@apollo/client'

const REQUEST_FOR_PHONE_LOGIN_MUTATION = gql`
    mutation requestOneTimePasswordForPhone($input: RequestOneTimePasswordForPhoneInput!) {
        requestOneTimePasswordForPhone(input: $input)
    }
`

export default async function requestForPhoneLogin(apolloClient, input, options) {
    const mutation = REQUEST_FOR_PHONE_LOGIN_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })

    return response
}
