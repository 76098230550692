import React from 'react'
import Spinner from '../../../util/Spinner'
import AssetCardContainer from './AssetCardContainer'

const AssetCardLoading = () => (
    <AssetCardContainer className="flex justify-center">
        <Spinner />
    </AssetCardContainer>
)

export default AssetCardLoading
