import React from 'react'
import * as theme from '../../../../styles/theme'
import FeatherIcon from '../../../util/FeatherIcon'
import AssetCardContainer from './AssetCardContainer'

const AssetCardPlaceholder = ({
    assetName,
    title,
    text,
    iconName,
    iconSize = theme.iconSizeLarge,
    iconInnerColor,
}) => (
    <AssetCardContainer>
        <h3 className="font-black text-3xl text-primary mb-4">
            {assetName}
        </h3>
        <div className="flex flex-1">
            <div className="flex flex-1 items-center justify-center mr-4">
                <FeatherIcon
                    name={iconName}
                    size={iconSize}
                    innerColor={iconInnerColor}
                />
            </div>
            <div className="flex flex-5 flex-col justify-center">
                <h5 className="font-black text-lg text-primary mb-0.5">{title}</h5>
                <div>{text}</div>
            </div>
        </div>
    </AssetCardContainer>
)

export default AssetCardPlaceholder
