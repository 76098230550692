import { gql } from '@apollo/client'
import { DEFAULT_USER_FRAGMENT } from './getInitialData'

const LOGIN_FOR_EMAIL_MUTATION = gql`
    mutation loginWithOneTimePasswordForEmail($input: LoginWithOneTimePasswordForEmailInput!) {
        loginWithOneTimePasswordForEmail(input: $input) {
            accessToken
            refreshToken
            me {
                ...DefaultUser
            }
        }
    }
    ${DEFAULT_USER_FRAGMENT}
`

export default async function loginWithPassForEmail(apolloClient, input, options) {
    const mutation = LOGIN_FOR_EMAIL_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })

    return response
}
