import './environment'
import '@reach/dialog/styles.css'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles.css'
import AppWithState from './components/AppWithState'
import initializeSentry from './sentry/sentry'
import initializeIntercom from './intercom/initializeIntercom'
import getApolloClient from './apollo/getApolloClient'

initializeSentry()
initializeIntercom()

const apolloClient = getApolloClient()

const Root = (
    <AppWithState
        apolloClient={apolloClient}
    />
)

ReactDOM.render(Root, document.getElementById('root'))
