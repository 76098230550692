import React from 'react'
import Popover from './Popover'

const Tooltip = ({
    content,
    ...props
}) => (
    <Popover
        type="hover"
        content={(options) => (
            <div className="w-56 rounded-default">
                <div
                    className="rounded-default p-3 bg-primary text-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {(() => {
                        if (typeof content === 'function') {
                            return content(options)
                        }
                        return content
                    })()}
                </div>
            </div>
        )}
        {...props}
    />
)

export default Tooltip
