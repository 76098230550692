import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import Input from '../../util/Input'
import FocusDidMount from '../../util/FocusDidMount'
import InputGroup from '../../util/InputGroup'

const LoginEmailForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => {
    const {
        getNativeInputProps,
        getErrorMessages,
    } = formState

    return (
        <form className="form" onSubmit={onSubmit}>
            <div className="mb-4">
                <FocusDidMount>
                    {(ref) => (
                        <InputGroup errorMessage={getErrorMessages('email')}>
                            <Input
                                {...getNativeInputProps('email')}
                                reference={ref}
                                type="email"
                                placeholder="Email address"
                            />
                        </InputGroup>
                    )}
                </FocusDidMount>
            </div>
            <div className="mb-4 flex items-center justify-between">
                <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                    onClick={onSubmit}
                >
                    Send code
                </button>
                <Link className="hyperlink" to={routes.login()}>
                    Back to choose
                </Link>
            </div>
        </form>
    )
}

export default LoginEmailForm
