import React from 'react'
import { formatCurrency } from '../../util/currency'

const Price = ({
    currencySymbol,
    currencyCode,
    price,
    unitCode,
}) => (
    <div className="flex items-center">
        <span className="font-medium">
            {currencySymbol}
        </span>
        <h3 className="text-primary text-2xl font-black mx-2">
            {formatCurrency(price, currencyCode)}
        </h3>
        <span className="font-medium">
            {unitCode}
        </span>
    </div>
)

export default Price
