import React from 'react'
import LogoVertical from '../util/LogoVertical'

const UnauthorizedFormWrapper = ({
    children,
}) => (
    <div className="mt-[-75px] w-[375px] min-h-[450px]">
        <div className="flex items-center justify-center mb-8">
            <LogoVertical height={100} lassName="mb-4" />
        </div>
        {children}
        <div className="flex mt-8">
            <div className="flex flex-1 justify-center">
                <a className="hyperlink" href="/terms-conditions.pdf" target="_blank">
                    Terms & Conditions
                </a>
            </div>
            <div className="flex flex-1 justify-center">
                <a className="hyperlink" href="https://valuetrading.eu/privacy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </div>
        </div>
    </div>
)

export default UnauthorizedFormWrapper
