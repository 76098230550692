import React from 'react'
import clsx from 'clsx'
import Triangle from '../../../util/Triangle'

const AssetCardSelect = ({
    className,
    value,
    onChange,
    children,
}) => (
    <div className={clsx('relative inline-block', className)}>
        <div className="absolute h-full flex items-center pointer-events-none top-0 right-2.5">
            <Triangle size={8} />
        </div>
        <select
            className="pr-6 pl-3 font-bold text-sm focus:border-primary hover:border-primary"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {children}
        </select>
    </div>
)

export default AssetCardSelect
