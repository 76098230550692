import React from 'react'

const MainCentered = ({
    children,
}) => (
    <main className="flex flex-col items-center justify-center w-full h-screen">
        {children}
    </main>
)

export default MainCentered
