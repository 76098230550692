import React from 'react'
import roundTo from 'round-to'
import clsx from 'clsx'
import {
    getFormattedMinimum,
    getFormattedMaximum,
} from '../../../../util/units'
import {
    UNIT_CODE_KILOGRAM,
    UNIT_CODE_GRAM,
    UNIT_CODE_OUNCE,
    GRAM_ROUND,
    OUNCE_ROUND,
    KILOGRAM_ROUND,
} from '../../../../util/constants'
import Triangle from '../../../util/Triangle'

const formatQuantity = (quantity, unitCode) => {
    // By multiplying, we make sure quantity's type is number
    const quantityNumber = quantity * 1
    switch (unitCode) {
        case UNIT_CODE_KILOGRAM:
            return roundTo(quantityNumber, KILOGRAM_ROUND)
        case UNIT_CODE_GRAM:
            return roundTo(quantityNumber, GRAM_ROUND)
        case UNIT_CODE_OUNCE:
            return roundTo(quantityNumber, OUNCE_ROUND)
        default:
            return roundTo(quantityNumber, 0)
    }
}

const getStep = (unitCode) => {
    switch (unitCode) {
        case UNIT_CODE_KILOGRAM:
            return `1e-${0}`
        case UNIT_CODE_GRAM:
            return `1e-${0}`
        case UNIT_CODE_OUNCE:
            return `1e-${OUNCE_ROUND}`
        default:
            return 1
    }
}

const QuantityInput = ({
    impurityId,
    min,
    max,
    onChange,
    value,
    unitCode,
    assetName,
    availableUnits,
    onChangeUnit,
}) => {
    const minimum = getFormattedMinimum(min, unitCode)
    const maximum = getFormattedMaximum(max, unitCode)
    const step = getStep(unitCode)
    const hasError = value < minimum || value > maximum
    return (
        <>
            <div className="mr-3 inline-flex">
                <input
                    step={step}
                    type="number"
                    value={value}
                    onChange={(e) => {
                        const v = formatQuantity(e.target.value, unitCode)
                        if (e.target.value === '') {
                            return onChange('')
                        }
                        return onChange(v)
                    }}
                    className={
                        clsx(
                            'rounded-default rounded-r-none border-r-0 w-[100px]',
                            hasError && 'border-red-500 border-r-1',
                        )
                    }
                />

                <div className="relative inline-block">
                    {(() => {
                        if (impurityId !== null) {
                            return (
                                <select
                                    disabled
                                    className="rounded-default rounded-l-none min-w-[60px]"
                                >
                                    <option selected="selected">{UNIT_CODE_GRAM}</option>
                                </select>
                            )
                        }
                        return (
                            <>
                                <div className="absolute flex h-full items-center top-0 right-2.5">
                                    <Triangle size={8} />
                                </div>
                                <select
                                    className="rounded-default rounded-l-none min-w-[60px]"
                                    value={unitCode}
                                    onChange={(e) => onChangeUnit(e.target.value)}
                                >
                                    {availableUnits.map(({ code }) => (
                                        <option
                                            key={code}
                                            value={code}
                                        >
                                            {code}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )
                    })()}
                </div>
            </div>
            <span>{assetName}</span>
        </>
    )
}

export default QuantityInput
