import React from 'react'
import ImpurityRow from './ImpurityRow'

const ImpuritiesTable = ({
    impurityMarketData = [],
    currencyCode,
    currencySymbol,
    unitCode,
    onClickSell,
}) => (
    <>
        <span className="text-sm font-medium">YOU SELL AT</span>
        {impurityMarketData.map((impurity) => (
            <ImpurityRow
                key={impurity.impurityId}
                impurity={impurity}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                unitCode={unitCode}
                onClickSell={onClickSell}
            />
        ))}
    </>
)

export default ImpuritiesTable
