import React from 'react'
import Spinner from './Spinner'

const SectionLoading = () => (
    <div className="flex items-center justify-center h-[200px]">
        <Spinner />
    </div>
)

export default SectionLoading
