import React from 'react'
import { currencyCodeToSymbol } from '../../../../util/currency'
import { getFormattedMinimum, getFormattedMaximum } from '../../../../util/units'
import Alert from '../../../util/Alert'
import CurrencyValue from '../../../util/CurrencyValue'
import Button from '../../../util/Button'
import AssetCardPrice from '../assetcard/AssetCardPrice'
import NewOrderTable from './NewOrderTable'

const NewOrderDetails = ({
    impurityName,
    side,
    asset,
    orderRequest,
    quantity,
    quantityUnitCode,
    onChangeQuantity,
    onNext,
    isValidQuantity,
    maxTradeLimit,
    minTradeLimit,
    availableUnits,
    onChangeUnit,
}) => {
    const {
        spotPrice, currencyCode, unitCode, impurityId,
    } = orderRequest
    const currencySymbol = currencyCodeToSymbol(currencyCode)

    return (
        <>
            <AssetCardPrice
                actionText={side === 'BUY'
                    ? 'BUYING AT'
                    : 'SELLING AT'}
                code={unitCode}
                currencySymbol={currencySymbol}
                spotPrice={spotPrice}
                currencyCode={currencyCode}
            />
            <NewOrderTable
                spotPrice={spotPrice}
                impurityId={impurityId}
                quantity={quantity}
                transactionFee="none"
                taxRate="none"
                onChangeQuantity={onChangeQuantity}
                assetSymbol={asset.code}
                unitCode={quantityUnitCode}
                currencySymbol={currencySymbol}
                assetName={asset.name.toLowerCase()}
                maxTradeLimit={maxTradeLimit}
                minTradeLimit={minTradeLimit}
                availableUnits={availableUnits}
                onChangeUnit={onChangeUnit}
            />
            {isValidQuantity
                ? (
                    <Alert isBlue className="text-center">
                        <p>You
                            {' '}{side === 'BUY' ? 'buy' : 'sell'}{' '}
                            <strong>{quantity || 0}{quantityUnitCode}</strong>
                            {' '}of{' '}
                            {asset.name.toLowerCase() }
                            {' '}at{' '}
                            <strong>
                                <CurrencyValue value={spotPrice} currencyCode={`${currencyCode}/${unitCode}`} />
                            </strong>.
                        </p>
                    </Alert>
                )
                : (
                    <Alert isRed className="text-center">
                        <p>
                            {`Select a quantity between ${getFormattedMinimum(minTradeLimit, unitCode)} ${unitCode} and ${getFormattedMaximum(maxTradeLimit, unitCode)} ${unitCode}`}
                        </p>
                    </Alert>
                )}
            <Button
                disabled={!isValidQuantity}
                className="btn-primary w-full"
                onClick={onNext}
            >
                {side} {impurityName !== null && ` ${impurityName}`}
            </Button>
        </>
    )
}

export default NewOrderDetails
