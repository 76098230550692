import { gql } from '@apollo/client'

const UPDATE_MY_CUSTOMER_PIN_MUTATION = gql`
    mutation updateMyCustomerPin($input: MyCustomerPinInput!) {
        updateMyCustomerPin(input: $input)
    }
`

export default async function updateMyCustomerPin(apolloClient, input, options) {
    const mutation = UPDATE_MY_CUSTOMER_PIN_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })
    return response
}
