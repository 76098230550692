import React from 'react'
import Logo from '../util/Logo'
import MainCentered from '../layout/MainCentered'

const AppFailure = () => (
    <MainCentered>
        <div className="mb-8">
            <Logo height={40} />
        </div>
        <h5>Something went wrong</h5>
        <p className="text-center">An unexpected error occurred while trying to load Value Trading.<br />Please try again later.</p>
    </MainCentered>
)

export default AppFailure
