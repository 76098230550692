import useFormState, { createFormValidation } from 'use-form-state'
import { isValidPhone } from '../../../validation/user'

const phoneRequestToFormValues = () => ({
    phone: '+32',
})

const validation = createFormValidation([{
    path: 'phone',
    validate: (phone) => isValidPhone(phone),
    message: 'Please fill in a valid phone number.',
}])

const toPhoneRequestInput = ({
    phone,
}) => ({
    phone: phone.replace(/ /g, ''),
})

const useLoginPhoneForm = (options) => (
    useFormState(
        phoneRequestToFormValues(),
        {
            validation,
            valuesToInput: toPhoneRequestInput,
            ...options,
        },
    )
)

export default useLoginPhoneForm
