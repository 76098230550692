import React from 'react'
import ConfigCache from '../../util/ConfigCache'

const UnitsSelect = ({
    name,
    value,
    onChange,
}) => (
    <ConfigCache>
        {({ units }) => (
            <select
                className="select capitalize"
                name={name}
                value={value}
                onChange={onChange}
            >
                {units.map((unit) => (
                    <option key={unit.code} value={unit.code}>
                        {unit.name}
                    </option>
                ))}
            </select>
        )}
    </ConfigCache>
)

export default UnitsSelect
