export const UNIT_CODE_MILLIGRAM = 'mg'
export const UNIT_CODE_GRAM = 'g'
export const UNIT_CODE_OUNCE = 'oz'
export const UNIT_CODE_KILOGRAM = 'kg'

export const GRAM_ROUND = 2
export const OUNCE_ROUND = 0
export const KILOGRAM_ROUND = 5

export const SIDE_BUY = 'BUY'
export const SIDE_SELL = 'SELL'

export const NOTIFICATION_TYPE_ERROR = 'ERROR'
export const NOTIFICATION_TYPE_SUCCESS = 'SUCCESS'
export const NOTIFICATION_TYPE_WARNING = 'WARNING'
