import useFormState, { createFormValidation } from 'use-form-state'
import {
    isValidFirstName,
    isValidLastName,
    isValidEmail,
    isValidPhone,
    isValidUnit,
    isValidCurrency,
} from '../../../validation/user'

const currentUserToSettingsFormValues = (currentUser) => ({
    firstName: currentUser.firstName || '',
    lastName: currentUser.lastName || '',
    email: currentUser.email || '',
    phone: currentUser.phone || '',
    preferredUnitCode: currentUser.preferredUnitCode || '',
    preferredCurrencyCode: currentUser.preferredCurrencyCode || '',
})

const validation = createFormValidation([{
    path: 'firstName',
    validate: (firstName) => isValidFirstName(firstName),
    message: 'Please fill in a valid first name.',
}, {
    path: 'lastName',
    validate: (lastName) => isValidLastName(lastName),
    message: 'Please fill in a valid last name.',
}, {
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: 'Please fill in a valid email.',
}, {
    path: 'phone',
    validate: (phone) => isValidPhone(phone),
    message: 'Please fill in a valid phone number.',
}, {
    path: 'preferredUnitCode',
    validate: (preferredUnitCode) => isValidUnit(preferredUnitCode),
    message: 'Please select a unit.',
}, {
    path: 'preferredCurrencyCode',
    validate: (preferredCurrencyCode) => isValidCurrency(preferredCurrencyCode),
    message: 'Please select a currency.',
}])

const toSettingsInput = ({
    firstName,
    lastName,
    email,
    phone,
    preferredUnitCode,
    preferredCurrencyCode,
}) => ({
    firstName,
    lastName,
    email,
    phone: phone.replace(/ /g, ''),
    preferredUnitCode,
    preferredCurrencyCode,
})

const useUpdateSettingsForm = (currentUser, options) => (
    useFormState(
        currentUserToSettingsFormValues(currentUser),
        {
            validation,
            valuesToInput: toSettingsInput,
            ...options,
        },
    )
)

export default useUpdateSettingsForm
