import React from 'react'
import Modal from '../../util/Modal'
import Button from '../../util/Button'
import TextArea from '../../util/TextArea'
import InputGroup from '../../util/InputGroup'

const UpdateOrderModal = ({
    formState,
    isVisible,
    isUpdating,
    onCloseModal,
    onUpdateOrder,
}) => {
    const {
        getErrorMessages,
        getNativeInputProps,
    } = formState
    return (
        <Modal
            aria-labelledby="Update order"
            isOpen={isVisible}
            onDismiss={onCloseModal}
        >
            <form className="form" onSubmit={onUpdateOrder}>
                <InputGroup
                    error={getErrorMessages('notes')}
                    label="Notes"
                >
                    <TextArea
                        rows={4}
                        className="resize-none"
                        {...getNativeInputProps('notes')}
                    />
                </InputGroup>
            </form>
            <Button
                type="submit"
                className="btn-primary w-full"
                loading={isUpdating}
                onClick={onUpdateOrder}
            >
                Save
            </Button>
        </Modal>
    )
}

export default UpdateOrderModal
