import React from 'react'
import {
    getCountries,
    parsePhoneNumber,
    getCountryPhoneCode,
    getCountryCodeFromString,
} from '../../util/phone'

const countries = getCountries()

class PhoneInput extends React.Component {
    constructor(props) {
        super(props)
        const {
            value,
            inputRef,
            selectRef,
        } = props

        this.state = {
            countryCode: getCountryCodeFromString(value) || 'BE',
        }

        this.inputRef = inputRef || React.createRef()
        this.selectRef = selectRef || React.createRef()
    }

    render() {
        const {
            name,
            placeholder,
            value,
            onChange,
        } = this.props
        const { countryCode } = this.state
        return (
            <div className="flex">
                <select
                    ref={this.selectRef}
                    className="w-[65px] border-r-0 rounded-r-none pr-7"
                    onChange={(e) => {
                        const countryCode = e.target.value
                        this.setState({ countryCode })
                        onChange(getCountryPhoneCode(countryCode))
                        this.inputRef.current.focus()
                    }}
                    value={countryCode}
                >
                    {countries.map(({
                        code,
                        name,
                        phone,
                    }) => (
                        <option
                            key={code}
                            value={code}
                        >
                            {code} - {name} (+{phone})
                        </option>
                    ))}
                </select>
                <input
                    ref={this.inputRef}
                    type="tel"
                    name={name}
                    placeholder={placeholder}
                    className="rounded-l-none"
                    value={value}
                    onChange={(e) => {
                        onChange(parsePhoneNumber(e.target.value, countryCode))
                    }}
                />
            </div>
        )
    }
}

export default PhoneInput
