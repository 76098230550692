import React from 'react'
import Page from '../../layout/Page'
import Navbar from '../../layout/Navbar'
import Main from '../../layout/Main'
import PageTitle from '../../layout/PageTitle'
import Spinner from '../../util/Spinner'
import Container from '../../util/Container'
import AssetCardWithState from './assetcard/AssetCardWithState'
import AssetCardSuspended from './assetcard/AssetCardSuspended'

const TradePage = ({
    isFetching,
    currentUser,
    myCustomerAssets,
}) => (
    <Page>
        <Navbar activeMenuItem="trade" />
        <Main>
            <Container className="flex flex-col items-center">
                <PageTitle
                    title="Tradable Metals"
                    text="Below you find the metals that you are eligible to trade"
                />
                {isFetching && <Spinner />}
                {!isFetching && myCustomerAssets.map(({
                    asset,
                    canSell,
                    canBuy,
                    suspended,
                    currencies,
                    units,
                }) => {
                    if (suspended || asset.suspended) {
                        return (
                            <AssetCardSuspended
                                key={asset.code}
                                assetName={asset.name}
                            />
                        )
                    }
                    return (
                        <AssetCardWithState
                            currentUser={currentUser}
                            asset={asset}
                            key={asset.code}
                            canSell={canSell}
                            canBuy={canBuy}
                            availableCurrencies={currencies}
                            availableUnits={units}
                        />
                    )
                })}
            </Container>
        </Main>
    </Page>
)

export default TradePage
