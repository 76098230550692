import { Component } from 'react'

const propsToInitialState = (props) => {
    const {
        initialState,
        initialValues,
        initialErrors,
    } = props
    return ({
        isLoading: false,
        isValid: false,
        values: initialValues,
        errors: typeof initialErrors !== 'undefined'
            ? initialErrors
            : Object
                .keys(initialValues || {})
                .reduce((acc, curr) => {
                    acc[curr] = null
                    return acc
                }, {}),
        ...initialState,
    })
}


class FormState extends Component {
    constructor(props) {
        super(props)

        this.isPristine = true
        this.state = propsToInitialState(props)

        this.publicSetState = (...args) => this.setState(...args)

        this.resetForm = this.resetForm.bind(this)
        this.mergeValues = this.mergeValues.bind(this)
        this.updateErrors = this.updateErrors.bind(this)
        this.checkValidation = this.checkValidation.bind(this)

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeAsValue = this.handleChangeAsValue.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    componentDidMount() {
        if (typeof this.props.didMount === 'function') {
            this.props.didMount(this.getChildProps())
        }
    }

    componentWillUnmount() {
        if (typeof this.props.willUnmount === 'function') {
            this.props.willUnmount(this.getChildProps())
        }
    }

    resetForm() {
        this.isPristine = true
        this.setState(propsToInitialState(this.props))
    }

    isValidForm() {
        return typeof this.props.isValidForm === 'function'
            ? this.props.isValidForm(this.state.values)
            : true
    }

    getFormErrors(state) {
        return typeof this.props.getFormErrors === 'function'
            ? this.props.getFormErrors(state.values)
            : {}
    }

    updateErrors(serverErrors = {}) {
        this.setState((state) => ({
            errors: {
                ...state.errors,
                ...this.getFormErrors(state),
                ...serverErrors,
            },
        }))
    }

    mergeValues(values) {
        this.setState((state) => ({
            values: {
                ...state.values,
                ...values,
            },
        }))
    }

    handleChange(e) {
        const {
            name, type, value, checked, files,
        } = e.target
        let finalValue = value
        if (type === 'checkbox') {
            finalValue = checked
        } else if (type === 'file') {
            [finalValue] = files
        }
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: finalValue,
            },
        }))
        if (!this.isPristine) {
            this.updateErrors()
        }
    }

    handleChangeAsValue(name, value) {
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: value,
            },
        }))
        if (!this.isPristine) {
            this.updateErrors()
        }
    }

    checkValidation() {
        this.isPristine = false
        const isValid = this.isValidForm()
        if (!isValid) {
            this.invalidFormWasSubmitted()
        } else {
            this.validFormWasSubmitted()
        }
        return isValid
    }

    handleCancel() {
        this.resetForm()
        if (typeof this.props.onCancel !== 'undefined') {
            this.props.onCancel()
        }
    }

    invalidFormWasSubmitted() {
        this.updateErrors()
        if (typeof this.props.onInvalidFormWasSubmitted === 'function') {
            this.props.onInvalidFormWasSubmitted()
        }
    }

    validFormWasSubmitted() {
        if (typeof this.props.onValidFormWasSubmitted === 'function') {
            this.props.onValidFormWasSubmitted()
        }
    }

    getChildProps() {
        return {
            state: this.state,
            errors: this.state.errors,
            values: this.state.values,
            setState: this.publicSetState,
            resetForm: this.resetForm,
            mergeValues: this.mergeValues,
            checkValidation: this.checkValidation,
            updateErrors: this.updateErrors,
            handleChange: this.handleChange,
            handleChangeAsValue: this.handleChangeAsValue,
            handleCancel: this.handleCancel,
        }
    }

    render() {
        if (this.props.debug) {
            console.log(this.state)
        }
        return this.props.children(this.getChildProps())
    }
}

export default FormState
