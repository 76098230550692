import React from 'react'
import Main from '../../layout/Main'
import Page from '../../layout/Page'
import Navbar from '../../layout/Navbar'
import PageTitle from '../../layout/PageTitle'
import Container from '../../util/Container'
import SettingsForm from './SettingsForm'
import LogoutButton from './LogoutButton'

const SettingsPage = ({
    currentUser,
    formState,
    isSubmitting,
    isLoggingOut,
    onOpenNewPinModal,
    onSaveCustomer,
    onLogout,
}) => (
    <Page>
        <Navbar settingsIsActive />
        <Main>
            <>
                <PageTitle
                    title="User Settings"
                    text={currentUser.customer.name}
                />
                <Container>
                    <div className="mx-auto max-w-[375px]">
                        <SettingsForm
                            formState={formState}
                            isSubmitting={isSubmitting}
                            onSubmit={onSaveCustomer}
                        />
                        <hr className="border-t-1 my-8" />
                        <button
                            type="button"
                            className="w-full btn-secondary mb-4"
                            onClick={onOpenNewPinModal}
                        >
                            Change personal pin
                        </button>
                        <LogoutButton
                            isLoggingOut={isLoggingOut}
                            onLogout={onLogout}
                        />
                    </div>
                </Container>
            </>
        </Main>
    </Page>
)

export default SettingsPage
