import React from 'react'
import Main from '../../layout/Main'
import Navbar from '../../layout/Navbar'
import PageTitle from '../../layout/PageTitle'
import Container from '../../util/Container'
import SectionLoading from '../../util/SectionLoading'
import BalanceCard from './BalanceCard'
import AssetWalletCard from './AssetWalletCard'
import MoneyWalletCard from './MoneyWalletCard'

const BalancePage = ({
    loading,
    customer,
    balance,
}) => (
    <div>
        <Navbar activeMenuItem="balance" />
        <Main>
            <Container>
                <PageTitle
                    title="Your Balance"
                    text="Below you find your current company balance and transactions."
                />
                <section className="flex justify-center mb-16">
                    {(() => {
                        if (loading) {
                            return <SectionLoading />
                        }
                        return (
                            <BalanceCard
                                balance={balance}
                                hasTradeLimit={customer.hasTradeLimit}
                                fixedTradeLimit={customer.fixedTradeLimit}
                            />
                        )
                    })()}
                </section>
                <section className="mb-16">
                    <h3 className="text-3xl text-primary font-black text-center mb-16">Metal Wallets</h3>
                    {(() => {
                        if (loading) {
                            return <SectionLoading />
                        }
                        const { assetWallets } = balance
                        return (
                            <div className="md:flex md:justify-center">
                                {assetWallets.map((assetWallet) => (
                                    <AssetWalletCard
                                        key={assetWallet.asset.id}
                                        wallet={assetWallet}
                                    />
                                ))}
                            </div>
                        )
                    })()}
                </section>
                <section className="mb-16">
                    <h3 className="text-3xl text-primary font-black text-center mb-16">Money Wallets</h3>
                    {(() => {
                        if (loading) {
                            return <SectionLoading />
                        }
                        const { moneyWallets } = balance
                        return (
                            <div className="md:flex md:justify-center">
                                {moneyWallets.map((moneyWallet) => (
                                    <MoneyWalletCard
                                        key={moneyWallet.currencyCode}
                                        wallet={moneyWallet}
                                    />
                                ))}
                            </div>
                        )
                    })()}
                </section>
            </Container>
        </Main>
    </div>
)

export default BalancePage
