import { gql } from '@apollo/client'

const REQUEST_FOR_EMAIL_LOGIN_MUTATION = gql`
    mutation requestOneTimePasswordForEmail($input: RequestOneTimePasswordForEmailInput!) {
        requestOneTimePasswordForEmail(input: $input)
    }
`

export default async function requestForEmailLogin(apolloClient, input, options) {
    const mutation = REQUEST_FOR_EMAIL_LOGIN_MUTATION
    const variables = { input }
    const response = await apolloClient.mutate({
        mutation,
        variables,
        ...options,
    })

    return response
}
