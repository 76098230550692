import * as validation from '../index'
import { unitToMilligram } from '../../util/units'

export const isValidQuantity = (quantity, max, min, unitCode) => (
    validation.isNumber(quantity)
    && validation.isGreaterOrEqualThan(min)(unitToMilligram(quantity, unitCode))
    && validation.isLessOrEqualThan(max)(unitToMilligram(quantity, unitCode))
)

export const isValidNewOrderForm = (values, max, min, unitCode) => (
    isValidQuantity(values.quantity, max, min, unitCode)
)
