import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import FocusDidMount from '../../util/FocusDidMount'
import InputGroup from '../../util/InputGroup'
import Input from '../../util/Input'

const LoginPasswordForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => {
    const {
        getNativeInputProps,
        getErrorMessages,
    } = formState

    return (
        <form className="form" onSubmit={onSubmit}>
            <div className="mb-4">
                <FocusDidMount>
                    {(ref) => (
                        <InputGroup errorMessage={getErrorMessages('password')}>
                            <Input
                                {...getNativeInputProps('password')}
                                reference={ref}
                                type="password"
                                placeholder="One time password"
                            />
                        </InputGroup>
                    )}
                </FocusDidMount>
            </div>
            <div className="mb-4 flex items-center justify-between">
                <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                    onClick={onSubmit}
                >
                    Login
                </button>
                <Link className="hyperlink" to={routes.login()}>Back to choose</Link>
            </div>
        </form>
    )
}

export default LoginPasswordForm
