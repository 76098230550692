import useFormState, { createFormValidation } from 'use-form-state'
import { isValidPassword } from '../../../validation/user'

const passwordToFormValues = (password) => ({
    password,
})

const validation = createFormValidation([{
    path: 'password',
    validate: (password) => isValidPassword(password),
    message: 'Please fill in a valid password.',
}])

const useLoginPasswordForm = (password, options) => (
    useFormState(
        passwordToFormValues(password),
        {
            validation,
            ...options,
        },
    )
)

export default useLoginPasswordForm
