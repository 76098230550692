import { useQuery, gql } from '@apollo/client'

const BALANCE_PAGE_QUERY = gql`
    query balancePageQuery {
        me {
            id
            customer {
                id
                hasTradeLimit
                fixedTradeLimit
                tradeLimit {
                    minTradeLimit
                    maxTradeLimit
                }
                balance {
                    total
                    tradeLimit
                    tradeMargin
                    assetWallets {
                        value
                        quantity
                        quantityInCredit
                        quantityInDebt
                        asset {
                            id
                            name
                            code
                        }
                    }
                    moneyWallets {
                        value
                        sellValue
                        buyValue
                        currencyCode
                    }
                }
            }
        }
    }
`

const useFetchCustomerSettingsPageData = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(BALANCE_PAGE_QUERY, {
        fetchPolicy: 'cache-and-network',
    })
    const { me = {} } = data
    const { customer = {} } = me
    const { balance = {} } = customer

    return {
        error,
        loading,
        customer,
        balance,
    }
}

export default useFetchCustomerSettingsPageData
