/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, forwardRef } from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { usePopper } from 'react-popper'
import { createPortal } from 'react-dom'
import { useOnClickOutside } from '@wappla/react-hooks'
import { combineRefs } from '../../util/index'

const POSITION_AUTO = 'auto'
const POSITION_AUTO_START = 'auto-start'
const POSITION_AUTO_END = 'auto-end'
const POSITION_TOP = 'top'
const POSITION_TOP_START = 'top-start'
const POSITION_TOP_END = 'top-end'
const POSITION_BOTTOM = 'bottom'
const POSITION_BOTTOM_START = 'bottom-start'
const POSITION_BOTTOM_END = 'bottom-end'
const POSITION_RIGHT = 'right'
const POSITION_RIGHT_START = 'right-start'
const POSITION_RIGHT_END = 'right-end'
const POSITION_LEFT = 'left'
const POSITION_LEFT_START = 'left-start'
const POSITION_LEFT_END = 'left-end'

const PopoverContainer = forwardRef(({
    onOutsideClick,
    ...props
}, ref) => {
    const containerRef = useRef()
    useOnClickOutside(containerRef, () => {
        if (typeof onOutsideClick === 'function') {
            onOutsideClick()
        }
    })
    const refs = ref !== null ? [ref, containerRef] : [containerRef]
    return <div ref={combineRefs(refs)} {...props} />
})

const AnimatedPopoverContainer = motion(PopoverContainer)

const Popover = ({
    type = 'click',
    position = POSITION_BOTTOM_START,
    content,
    isInline = true,
    className,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [popperElement, setPopperElement] = useState(null)
    const [referenceElement, setReferenceElement] = useState(null)
    const { styles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
            strategy: 'fixed',
            placement: position,
        },
    )

    const childProps = {}
    if (type === 'click') {
        childProps.onClick = () => setIsOpen(true)
    }
    if (type === 'hover') {
        childProps.onMouseEnter = () => setIsOpen(true)
        childProps.onMouseLeave = () => setIsOpen(false)
    }
    return (
        <>
            <span
                className={clsx(
                    'focus:outline-none ',
                    isInline ? 'inline-block' : 'block',
                    className,
                )}
                ref={setReferenceElement}
                {...childProps}
            >
                {children}
            </span>
            {isOpen && createPortal(
                <AnimatedPopoverContainer
                    onOutsideClick={() => setIsOpen(false)}
                >
                    <div
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        {(() => {
                            if (typeof content === 'function') {
                                return content({
                                    open: () => setIsOpen(true),
                                    close: () => setIsOpen(false),
                                })
                            }
                            return content
                        })()}
                    </div>
                </AnimatedPopoverContainer>,
                document.querySelector('#popover'),
            )}
        </>
    )
}

Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_AUTO = POSITION_AUTO
Popover.POSITION_AUTO_START = POSITION_AUTO_START
Popover.POSITION_AUTO_END = POSITION_AUTO_END
Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_TOP_START = POSITION_TOP_START
Popover.POSITION_TOP_END = POSITION_TOP_END
Popover.POSITION_BOTTOM = POSITION_BOTTOM
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START
Popover.POSITION_BOTTOM_END = POSITION_BOTTOM_END
Popover.POSITION_RIGHT = POSITION_RIGHT
Popover.POSITION_RIGHT_START = POSITION_RIGHT_START
Popover.POSITION_RIGHT_END = POSITION_RIGHT_END
Popover.POSITION_LEFT = POSITION_LEFT
Popover.POSITION_LEFT_START = POSITION_LEFT_START
Popover.POSITION_LEFT_END = POSITION_LEFT_END

export default Popover
