import React from 'react'
import AssetCardContainer from './AssetCardContainer'

const AssetCardLoading = () => (
    <AssetCardContainer>
        <h3 className="font-black text-3xl text-center text-primary mb-4">Error</h3>
        <p className="text-center">It looks like something went wrong fetching this asset.</p>
    </AssetCardContainer>
)

export default AssetCardLoading
