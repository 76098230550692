import React from 'react'
import Spinner from './Spinner'
import ScrollSentinel from './ScrollSentinel'

const InfiniteScroll = ({
    isFetchingMore,
    onFetchMore,
    hasNextPage,
    children,
}) => (
    <div className="relative">
        {children}
        {(isFetchingMore) && (
            <div className="flex justify-center items-center h-10">
                <Spinner size={20} />
            </div>
        )}
        {(!isFetchingMore && hasNextPage) && (
            <ScrollSentinel
                onIntersect={onFetchMore}
            />
        )}
    </div>
)
export default InfiniteScroll
