import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import { readQueryString } from '../../../util/index'
import loginWithPassForPhone from '../../../api/loginWithPassForPhone'
import loginWithPassForEmail from '../../../api/loginWithPassForEmail'
import useLoginPasswordForm from './useLoginPasswordForm'
import LoginPasswordPage from './LoginPasswordPage'

const LoginPasswordPageWithState = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { dispatchGraphqlError } = useNotifications()
    const { email, phone } = readQueryString(location.search)
    const emailWasSent = typeof email !== 'undefined'
    const [isSubmitting, setIsSubmitting] = useState(false)
    const apolloClient = useApolloClient()
    const formState = useLoginPasswordForm('')
    const { login } = useAppState()

    return (
        <LoginPasswordPage
            formState={formState}
            isSubmitting={isSubmitting}
            email={email}
            phone={phone}
            emailWasSent={emailWasSent}
            onSubmit={async (e) => {
                e.preventDefault()
                const isValid = formState.validate()
                if (isValid) {
                    try {
                        setIsSubmitting(true)

                        const input = {
                            oneTimePassword: formState.values.password,
                            phone: phone || undefined,
                            email: email || undefined,
                        }
                        const response = phone
                            ? await loginWithPassForPhone(apolloClient, input)
                            : await loginWithPassForEmail(apolloClient, input)
                        const loginPayload = phone
                            ? response.data.loginWithOneTimePasswordForPhone
                            : response.data.loginWithOneTimePasswordForEmail
                        const { accessToken, refreshToken } = loginPayload
                        const currentUser = loginPayload.me

                        await login(accessToken, refreshToken, currentUser)
                        navigate('/')
                    } catch (error) {
                        setIsSubmitting(false)
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default LoginPasswordPageWithState
