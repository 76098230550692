import React from 'react'
import MainCentered from '../layout/MainCentered'
import Logo from '../util/Logo'
import Spinner from '../util/Spinner'

const AppLoading = () => (
    <MainCentered>
        <div className="mb-12">
            <Logo height={40} />
        </div>
        <Spinner
            size={40}
            className="text-primary"
        />
    </MainCentered>
)

export default AppLoading
