import React from 'react'
import clsx from 'clsx'
import { formatCurrency } from '../../util/currency'

const CurrencyValue = ({
    value,
    isNegative,
    isPositive,
    currencyCode,
}) => (
    <span>
        {(isPositive || isNegative) && (
            <span
                className={clsx(
                    isPositive ? 'text-green-500' : 'text-red-500',
                )}
            >
                {isPositive ? '+' : '-'}
            </span>
        )}
        {' '}
        {formatCurrency(value, currencyCode)}
        {' '}
        {currencyCode}
    </span>
)

export default CurrencyValue
