import React, { forwardRef } from 'react'
import clsx from 'clsx'

const Container = forwardRef(({
    as = 'div',
    children,
    className,
}, ref) => {
    const Component = as
    return (
        <Component
            ref={ref}
            className={clsx([
                'max-w-screen-lg mx-auto px-4',
                'xl:max-w-screen-xl',
                className,
            ])}
        >
            {children}
        </Component>
    )
})

export default Container
