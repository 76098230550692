import useFormState, { createFormValidation } from 'use-form-state'
import {
    isValidPin,
    isPinLongEnough,
    isValidRepeatPin,
    isValidFirstName,
    isValidLastName,
    isValidEmail,
    isValidPhone,
    isValidUnit,
    isValidCurrency,
} from '../../../validation/user'

const currentUserToOnboardingFormValues = (currentUser) => ({
    pin: '',
    repeatPin: '',
    firstName: currentUser.firstName || '',
    lastName: currentUser.lastName || '',
    email: currentUser.email || '',
    phone: currentUser.phone || '',
    preferredUnitCode: currentUser.preferredUnitCode || '',
    preferredCurrencyCode: currentUser.preferredCurrencyCode || '',
    termsAndConditions: false,
    privacyPolicy: false,
})

const validation = createFormValidation([{
    path: 'pin',
    validate: (pin) => isValidPin(pin) && isPinLongEnough(pin),
    message: 'Please fill in a valid personal code.',
}, {
    path: 'repeatPin',
    validate: (repeatPin, { pin }) => isValidRepeatPin(pin, repeatPin),
    message: 'Please fill in the same pin.',
}, {
    path: 'firstName',
    validate: (firstName) => isValidFirstName(firstName),
    message: 'Please fill in a valid first name.',
}, {
    path: 'lastName',
    validate: (lastName) => isValidLastName(lastName),
    message: 'Please fill in a valid last name.',
}, {
    path: 'email',
    validate: (email) => isValidEmail(email),
    message: 'Please fill in a valid email.',
}, {
    path: 'phone',
    validate: (phone) => isValidPhone(phone),
    message: 'Please fill in a valid phone number.',
}, {
    path: 'preferredUnitCode',
    validate: (preferredUnitCode) => isValidUnit(preferredUnitCode),
    message: 'Please select a unit.',
}, {
    path: 'preferredCurrencyCode',
    validate: (preferredCurrencyCode) => isValidCurrency(preferredCurrencyCode),
    message: 'Please select a currency.',
}, {
    path: 'termsAndConditions',
    validate: (termsAndConditions) => termsAndConditions,
    message: 'Please read the terms and conditions.',
}, {
    path: 'privacyPolicy',
    validate: (privacyPolicy) => privacyPolicy,
    message: 'Please read the privacy policy.',
}])

const toNewOnboardingInput = ({
    pin,
    firstName,
    lastName,
    email,
    phone,
    preferredUnitCode,
    preferredCurrencyCode,
}) => ({
    pin,
    firstName,
    lastName,
    email,
    phone: phone.replace(/ /g, ''),
    preferredUnitCode,
    preferredCurrencyCode,
})

const useCompleteOnboardingForm = (currentUser, options) => (
    useFormState(
        currentUserToOnboardingFormValues(currentUser),
        {
            validation,
            valuesToInput: toNewOnboardingInput,
            ...options,
        },
    )
)

export default useCompleteOnboardingForm
