import React from 'react'
import useAppState from '../hooks/useAppState'
import CurrencyValue from './CurrencyValue'

const PreferredCurrencyValue = ({
    value,
    isNegative,
    isPositive,
}) => {
    const { currentUser } = useAppState()
    return (
        <CurrencyValue
            value={value}
            isNegative={isNegative}
            isPositive={isPositive}
            currencyCode={currentUser.preferredCurrencyCode}
        />
    )
}

export default PreferredCurrencyValue
