import clsx from 'clsx'
import React from 'react'

const Spinner = ({
    size = 50,
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 70 70"
        preserveAspectRatio="xMidYMid"
        className={clsx('animate-spin', className)}
    >
        <circle
            cx="35"
            cy="35"
            fill="none"
            stroke="black"
            strokeWidth="4"
            r="30"
            strokeDasharray="164.93361431346415 56.97787143782138"
        />
    </svg>
)

export default Spinner
