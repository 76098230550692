import * as validation from './index'

export const isValidPassword = (password) => (
    validation.isValue(password)
)

export const isValidEmail = (email) => (
    validation.isValue(email)
    && validation.isEmail(email)
)

export const isValidPhone = (phone) => (
    validation.isValue(phone)
    && validation.isPhone(phone)
)

export const isValidPin = (pin) => (
    validation.isValue(pin)
)

export const isPinLongEnough = (pin) => (
    validation.isValue(pin)
    && validation.isEqual(4, pin.length)
)

export const isValidRepeatPin = (pin, repeatPin) => (
    validation.isValue(pin)
    && validation.isValue(repeatPin)
    && validation.isEqual(pin, repeatPin)
)

export const isValidFirstName = (firstName) => (
    validation.isValue(firstName)
)

export const isValidLastName = (lastName) => (
    validation.isValue(lastName)
)

export const isValidUnit = (unit) => (
    validation.isValue(unit)
)

export const isValidCurrency = (currency) => (
    validation.isValue(currency)
)
