import React from 'react'
import clsx from 'clsx'

const Tag = ({
    isRed,
    isBlue,
    isGreen,
    isYellow,
    className,
    children,
}) => (
    <span
        className={
            clsx(
                'rounded-md py-0.5 px-4 text-sm',
                isRed && 'bg-red-500',
                isBlue && 'bg-blue-500',
                isGreen && 'bg-green-500',
                isYellow && 'bg-yellow-500',
                className,
            )
        }
    >
        {children}
    </span>
)

export default Tag
