import React from 'react'
import clsx from 'clsx'
import { SIDE_BUY } from '../../../../util/constants'
import { formatCurrency } from '../../../../util/currency'
import Triangle from '../../../util/Triangle'

const STABLE = 'isStable'
const PROFITABLE = 'isProfitable'
const NOT_PROFITABLE = 'isNotProfitable'

const AssetCardPrice = ({
    side,
    code,
    currencySymbol,
    spotPrice,
    previousSpotPrice,
    currencyCode,
}) => {
    let actionText = 'YOU SELL AT'
    if (side === SIDE_BUY) {
        actionText = 'YOU BUY AT'
    }

    let profitableState = STABLE
    if (spotPrice < previousSpotPrice) {
        if (side === SIDE_BUY) {
            profitableState = PROFITABLE
        } else {
            profitableState = NOT_PROFITABLE
        }
    }
    if (spotPrice > previousSpotPrice) {
        if (side === SIDE_BUY) {
            profitableState = NOT_PROFITABLE
        } else {
            profitableState = PROFITABLE
        }
    }

    return (
        <div className="mb-4">
            <div className="text-sm">
                <span className="font-medium">{actionText}</span>
                {profitableState !== STABLE && (
                    <div
                        className={clsx(
                            'inline-block mb-[2px] ml-2',
                            profitableState === PROFITABLE && 'text-green-500 fill-current',
                            profitableState === NOT_PROFITABLE && 'text-red-500 fill-current',
                        )}
                    >
                        <Triangle direction={profitableState === PROFITABLE ? 'up' : 'down'} />
                    </div>
                )}
            </div>
            <div className="flex">
                <span className="text-xl mr-2 font-medium">
                    {currencySymbol}
                </span>
                <h2
                    className={clsx(
                        'text-primary inline text-4xl font-black',
                        profitableState === PROFITABLE && 'text-green-500 fill-current',
                        profitableState === NOT_PROFITABLE && 'text-red-500 fill-current',
                    )}
                >
                    {formatCurrency(spotPrice, currencyCode)}
                </h2>
                <span className="self-end text-xl ml-2 font-medium">{code}</span>
            </div>
        </div>
    )
}

export default AssetCardPrice
