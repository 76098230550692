import React from 'react'
import useNotifications from '../../hooks/useNotifications'
import UpdateOrderModal from './UpdateOrderModal'
import useUpdateOrderMutation from './useUpdateOrderMutation'
import useUpdateOrderFormState from './useUpdateOrderFormState'

const UpdateOrderModalWithState = ({
    order,
    onUpdateOrderCompleted,
    ...props
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const formState = useUpdateOrderFormState({
        notes: order.notes === null ? '' : order.notes,
    })
    const [updateOrder, { loading }] = useUpdateOrderMutation()

    const handleUpdateOrder = async () => {
        try {
            await updateOrder({
                variables: {
                    id: order.id,
                    input: formState.values,
                },
            })
            dispatchSuccess({
                message: 'The order was successfully updated!',
            })
            onUpdateOrderCompleted()
        } catch (error) {
            dispatchGraphqlError(error)
        }
    }

    return (
        <UpdateOrderModal
            {...props}
            formState={formState}
            isUpdating={loading}
            onUpdateOrder={handleUpdateOrder}
        />
    )
}

export default UpdateOrderModalWithState
