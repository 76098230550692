import React from 'react'
import {
    SIDE_BUY, SIDE_SELL, UNIT_CODE_GRAM,
} from '../../../../util/constants'
import AssetCardPrice from './AssetCardPrice'
import AssetCardAction from './AssetCardAction'
import AssetCardSelect from './AssetCardSelect'
import ImpuritiesTable from './ImpuritiesTable'
import AssetCardContainer from './AssetCardContainer'

const WIDTH = 64

const AssetCard = ({
    marketData,
    name,
    code,
    canSell,
    canBuy,
    unitCode,
    currencyCode,
    currencySymbol,
    availableCurrencies,
    availableUnits,
    onChangeCurrencyCode,
    onChangeUnitCode,
    onClickBuy,
    onClickSell,
    onToggleKarats,
    isShowingKarats,
}) => (
    <AssetCardContainer>
        <div className="flex justify-between items-center mb-3">
            <h3 className="font-black text-3xl text-primary">
                {name}
            </h3>
            <div>
                <AssetCardSelect
                    className="mr-4"
                    value={currencyCode}
                    onChange={onChangeCurrencyCode}
                >
                    {availableCurrencies.map((currency) => (
                        <option
                            key={currency.code}
                            value={currency.code}
                        >
                            {code}
                            {' '}
                            /
                            {' '}
                            {currency.code}
                        </option>
                    ))}
                </AssetCardSelect>
                <AssetCardSelect
                    value={unitCode}
                    onChange={onChangeUnitCode}
                >
                    {availableUnits.map((unit) => (
                        <option
                            key={unit.code}
                            value={unit.code}
                        >
                            {unit.code}
                        </option>
                    ))}
                </AssetCardSelect>
            </div>
        </div>
        <div className="flex flex-1 flex-col sm:flex-row">
            <div className="flex-1 sm:mr-4">
                <AssetCardPrice
                    side={SIDE_SELL}
                    code={unitCode}
                    currencySymbol={currencySymbol}
                    spotPrice={marketData.sellPrice}
                    previousSpotPrice={marketData.previousSellPrice}
                    currencyCode={currencyCode}
                />
                <AssetCardAction
                    isDisabled={!canSell}
                    actionText="SELL"
                    onClick={onClickSell}
                />
            </div>
            <div className="flex-1">
                <AssetCardPrice
                    side={SIDE_BUY}
                    code={unitCode}
                    currencySymbol={currencySymbol}
                    spotPrice={marketData.buyPrice}
                    previousSpotPrice={marketData.previousBuyPrice}
                    currencyCode={currencyCode}
                />
                <AssetCardAction
                    isDisabled={!canBuy}
                    actionText="BUY"
                    onClick={onClickBuy}
                />
            </div>
        </div>
        {window.env.REACT_APP_SHOW_IMPURITIES && unitCode === UNIT_CODE_GRAM && (
            <>
                <div className="text-center">
                    <button
                        type="button"
                        onClick={onToggleKarats}
                    >
                        {isShowingKarats ? 'Hide Karats' : 'Show Karats'}
                    </button>
                </div>
                {isShowingKarats && (
                    <ImpuritiesTable
                        impurityMarketData={marketData.impurities}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        unitCode={unitCode}
                        onClickSell={onClickSell}
                    />
                )}
            </>
        )}
    </AssetCardContainer>
)

AssetCard.WIDTH = WIDTH

export default AssetCard
