import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import * as routes from '../../../routes/index'
import requestForEmailLogin from '../../../api/requestForEmailLogin'
import useNotifications from '../../hooks/useNotifications'
import useLoginEmailForm from './useLoginEmailForm'
import LoginEmailPage from './LoginEmailPage'

const LoginEmailPageWithState = () => {
    const navigate = useNavigate()
    const { dispatchGraphqlError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const apolloClient = useApolloClient()
    const formState = useLoginEmailForm()

    return (
        <LoginEmailPage
            formState={formState}
            isSubmitting={isSubmitting}
            onSubmit={async (e) => {
                e.preventDefault()
                const isValid = formState.validate()
                if (isValid) {
                    try {
                        setIsSubmitting(true)
                        await requestForEmailLogin(apolloClient, formState.values)

                        navigate(`/login/password?email=${encodeURIComponent(formState.values.email)}`)
                    } catch (e) {
                        setIsSubmitting(false)
                        dispatchGraphqlError(e)
                    }
                }
            }}
        />
    )
}

export default LoginEmailPageWithState
