import React from 'react'
import InfiniteScroll from '../../util/InfiniteScroll'
import SectionLoading from '../../util/SectionLoading'
import Container from '../../util/Container'
import OrderListItem from './OrderListItem'

const OrderList = ({
    currentUser,
    orders,
    isFetching,
    isFetchingMore,
    hasNextPage,
    onFetchMore,
    onEditOrder,
}) => {
    if (isFetching) {
        return <SectionLoading />
    }
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            hasNextPage={hasNextPage}
            onFetchMore={onFetchMore}
        >
            <Container>
                <ul>
                    {orders.map((order) => (
                        <OrderListItem
                            key={order.uid}
                            order={order}
                            currentUserId={currentUser.id}
                            onEditOrder={onEditOrder}
                        />
                    ))}
                </ul>
            </Container>
        </InfiniteScroll>
    )
}

export default OrderList
