import React from 'react'
import { Routes, Route } from 'react-router-dom'
import * as routes from '../routes'
import { INITIAL_DATA_QUERY } from '../api/getInitialData'
import LoginPage from './pages/login/LoginPage'
import LoginEmailPageWithState from './pages/login-email/LoginEmailPageWithState'
import LoginPhonePageWithState from './pages/login-phone/LoginPhonePageWithState'
import LoginPasswordPageWithState from './pages/login-password/LoginPasswordPageWithState'
import BalancePageWithState from './pages/balance/BalancePageWithState'
import TradePageWithState from './pages/trade/TradePageWithState'
import HistoryPageWithState from './pages/history/HistoryPageWithState'
import SettingsPageWithState from './pages/settings/SettingsPageWithState'
import OnboardingPageWithState from './pages/onboarding/OnboardingPageWithState'

const AppRouter = ({
    isAuthenticated,
    currentUser,
}) => {
    if (!isAuthenticated) {
        return (
            <Routes>
                <Route exact path={routes.login()} element={<LoginPage />} />
                <Route exact path={routes.loginEmail()} element={<LoginEmailPageWithState />} />
                <Route exact path={routes.loginPhone()} element={<LoginPhonePageWithState />} />
                <Route exact path={routes.loginPassword()} element={<LoginPasswordPageWithState />} />
                <Route path="/" element={<LoginPage />} />
            </Routes>
        )
    }
    if (!currentUser.hasCompletedOnboarding) {
        return <OnboardingPageWithState queriesToRefetch={[{ query: INITIAL_DATA_QUERY }]} />
    }
    return (
        <Routes>
            <Route exact path={routes.home()} element={<TradePageWithState />} />
            <Route exact path={routes.balance()} element={<BalancePageWithState />} />
            <Route exact path={routes.trade()} element={<TradePageWithState />} />
            <Route exact path={routes.history()} element={<HistoryPageWithState />} />
            <Route exact path={routes.settings()} element={<SettingsPageWithState />} />
            <Route path="/" element={<TradePageWithState />} />
        </Routes>
    )
}

export default AppRouter
