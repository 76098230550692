import React from 'react'
import FeatherIcon from '../../../util/FeatherIcon'
import NewOrderContainer from './NewOrderContainer'

const NewOrderFailure = ({
    onDone,
    error = 'An error occurred when processing your trade request.',
    text = 'Please try again later.',
}) => (
    <NewOrderContainer>
        <div className="flex items-center justify-center p-8 mb-4 h-[100px] w-[100px] bg-red-500 text-white rounded-full">
            <FeatherIcon size={56} name="cross" />
        </div>
        <p className="m-0">
            {error}
        </p>
        <p>{text}</p>
        <button
            type="button"
            className="btn-secondary"
            onClick={onDone}
        >
            Done
        </button>
    </NewOrderContainer>
)

export default NewOrderFailure
