import React from 'react'
import TimeRemaining from './TimeRemaining'

const NewOrderHeader = ({
    title = 'New order',
    showTimeRemaining = true,
    orderRequestedAt,
    onRequestExpired,
}) => (
    <>
        <h3 className="text-primary font-black text-3xl">{title}</h3>
        {showTimeRemaining && (
            <TimeRemaining
                orderRequestedAt={orderRequestedAt}
                onRequestExpired={onRequestExpired}
            />
        )}
    </>
)

export default NewOrderHeader
