import React from 'react'

const PageTitle = ({
    title,
    text,
}) => (
    <section className="flex items-center flex-col text-center mb-8">
        <h3 className="text-3xl text-primary font-black text-center mb-6">{title}</h3>
        <p className="mb-0">{text}</p>
    </section>
)

export default PageTitle
