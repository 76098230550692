import React from 'react'
import PinInput from 'react-pin-input'

const FormPinInput = ({
    focus,
    onChange,
}) => (
    <PinInput
        length={4}
        focus={focus}
        secret
        inputStyle={{
            width: '2rem',
            height: '2rem',
            borderRadius: '0.4rem',
        }}
        onChange={onChange}
    />
)

export default FormPinInput
