import React from 'react'
import Spinner from '../../../util/Spinner'
import NewOrderContainer from './NewOrderContainer'

export default function NewOrderRequestLoading() {
    return (
        <NewOrderContainer>
            <Spinner />
        </NewOrderContainer>
    )
}
