import { gql, useMutation } from '@apollo/client'
import createApolloClient from '../apollo/createApolloClient'

const MUTATION = gql`
    mutation logout($refreshToken: String!) {
        logout(refreshToken: $refreshToken)
    }
`

export const useLogoutMutation = (options) => (
    useMutation(MUTATION, options)
)

export default async function logout(refreshToken) {
    const apolloClient = createApolloClient()
    const mutation = MUTATION
    const variables = { refreshToken }
    try {
        const response = await apolloClient.mutate({ mutation, variables })
        return response.data.logout
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to logout.')
        return null
    }
}
