const STAGING = 'staging'

// VERCEL_GIT_COMMIT_REF references to git branch name

function setEnvironment() {
    window.env = process.env
    if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === STAGING) {
        window.env = {
            REACT_APP_ENV: process.env.REACT_APP_ENV_STAGING,
            REACT_APP_SERVER_URI: process.env.REACT_APP_SERVER_URI_STAGING,
            REACT_APP_PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY_STAGING,
            REACT_APP_SHOW_IMPURITIES: process.env.REACT_APP_SHOW_IMPURITIES_STAGING,
        }
    }
}

setEnvironment()
