import React from 'react'
import Popover from './Popover'

const DropdownMenu = ({
    content,
    ...props
}) => (
    <Popover
        content={(options) => (
            <div className="w-56 py-3 rounded-default shadow-lg bg-white">
                <div
                    className="rounded-md shadow-xs py-smallest"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {(() => {
                        if (typeof content === 'function') {
                            return content(options)
                        }
                        return content
                    })()}
                </div>
            </div>
        )}
        {...props}
    />
)

export default DropdownMenu
