import React from 'react'
import useAppState from '../hooks/useAppState'
import UnitValue from './UnitValue'

const PreferredUnitValue = ({
    value,
}) => {
    const { currentUser } = useAppState()
    return (
        <UnitValue
            value={value}
            unitCode={currentUser.preferredUnitCode}
        />
    )
}

export default PreferredUnitValue
