import { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { PAGE_INFO_FRAGMENT, connectionToCollection, mergeQueryResults } from '../../../util/graphql'

export const ORDER_LIST_ITEM_FRAGMENT = gql`
    fragment OrderListItem on Order {
        id
        uid
        createdAt
        side
        quantity
        quantityUnitCode
        status
        notes
        price
        priceUnitCode
        totalPrice
        currencyCode
        impurity {
            id
            name
        }
        asset {
            name
            code
        }
        user {
            id
            fullName
            role {
                id
            }
        }
        receitUrl
    }
`

const HISTORY_PAGE_QUERY = gql`
    query myOrderOverview($after: String, $first: Int) {
        myCustomerOrders(
            after: $after,
            first: $first,
            orderBy: {
                createdAt: DESC
            }
        ) {
            edges {
                node {
                    ...OrderListItem
                }
                cursor
            }
            pageInfo {
                ...PageInfo
            }
            totalCount
        }
    }
    ${PAGE_INFO_FRAGMENT}
    ${ORDER_LIST_ITEM_FRAGMENT}
`

const useFetchHistoryPageData = (first, after) => {
    const [isFetchingMoreOrders, setIsFetchingMoreOrders] = useState(false)
    const variables = { first, after }
    const {
        error,
        loading,
        data = {},
        fetchMore,
        ...result
    } = useQuery(HISTORY_PAGE_QUERY, {
        variables,
    })

    const handleFetchMoreOrders = async () => {
        setIsFetchingMoreOrders(true)
        const refetchAfter = data.myCustomerOrders.pageInfo.endCursor
        await fetchMore({
            variables: {
                ...variables,
                after: refetchAfter,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMoreOrders(false)
                return mergeQueryResults(previousResult, fetchMoreResult, 'myCustomerOrders')
            },
        })
    }

    return {
        error,
        hasError: typeof error !== 'undefined',
        isFetching: loading,
        orders: connectionToCollection(data.myCustomerOrders),
        isFetchingMore: isFetchingMoreOrders,
        hasNextPage: !loading && !error && data.myCustomerOrders.pageInfo.hasNextPage,
        handleFetchMoreOrders,
        ...result,
    }
}

export default useFetchHistoryPageData
