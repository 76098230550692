import React from 'react'
import { motion } from 'framer-motion'

const BalanceCardContainer = ({
    children,
    ...props
}) => (
    <motion.div
        className="relative overflow-visible transition-transform duration-300 ease-in-out rounded-md py-4 px-6 mb-4 md:mr-4 md:min-w-[400px] md:mb-0 shadow-md hover:shadow-lg border-1 border-gray-200"
        whileHover={{
            scale: 1.02,
        }}
        {...props}
    >
        {children}
    </motion.div>
)

export default BalanceCardContainer
