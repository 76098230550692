import React from 'react'
import Button from '../../util/Button'
import SettingsFormFields from './SettingsFormFields'

const SettingsForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => (
    <form className="form" onSubmit={onSubmit}>
        <SettingsFormFields
            focusFirstInputOnMount
            formState={formState}
        />
        <Button
            className="w-full btn-primary"
            loading={isSubmitting}
            onClick={onSubmit}
        >
            Save
        </Button>
    </form>
)

export default SettingsForm
