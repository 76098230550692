import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import Modal from '../../../util/Modal'
import useNotifications from '../../../hooks/useNotifications'
import { graphqlErrorsToFormErrors } from '../../../../util/graphql'
import updateMyCustomerPin from '../../../../api/updateMyCustomerPin'
import useUpdatePinForm from './useUpdatePinForm'
import NewPinForm from './NewPinForm'

const UpdatePinModalWithState = ({
    isVisible,
    closeModal,
}) => {
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const apolloClient = useApolloClient()
    const formState = useUpdatePinForm()

    return (
        <Modal
            aria-labelledby="Update your pin"
            isOpen={isVisible}
            onDismiss={closeModal}
        >
            <h3>Change Personal Pin</h3>
            <NewPinForm
                formState={formState}
                isSubmitting={isSubmitting}
                onSubmit={async (e) => {
                    e.preventDefault()
                    if (formState.validate()) {
                        try {
                            setIsSubmitting(true)
                            await updateMyCustomerPin(apolloClient, formState.valuesToInput())
                            setIsSubmitting(false)
                            dispatchSuccess({
                                message: 'Your pincode was successfully updated.',
                            })
                            closeModal()
                        } catch (errors) {
                            const formErrors = graphqlErrorsToFormErrors(errors.graphQLErrors)
                            if (formErrors.length !== 0) {
                                formState.updateErrors(formErrors)
                            } else {
                                dispatchGraphqlError(errors)
                                closeModal()
                            }
                            setIsSubmitting(false)
                        }
                    }
                }}
            />
        </Modal>
    )
}

export default UpdatePinModalWithState
