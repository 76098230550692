import { gql } from '@apollo/client'

export const connectionToCollection = (connection = { edges: [] }) => connection.edges.map((edge) => edge.node)

export const nestedConnectionToCollection = (key) => (entity) => ({
    ...entity,
    [key]: connectionToCollection(entity[key]),
})

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const {
        edges: newEdges,
        totalCount,
        pageInfo,
    } = fetchMoreResult[path]

    return newEdges.length ? {
        [path]: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult[path].__typename,
            edges: [...previousResult[path].edges, ...newEdges],
            pageInfo,
            totalCount,
        },
    } : previousResult
}

export const graphqlErrorsToFormErrors = (errors) => errors.reduce((_, error) => {
    const { invalidFields } = error.extensions.exception
    return Object.keys(invalidFields).map((key) => ({
        path: key,
        message: invalidFields[key],
    }))
}, [])

export const isUnauthenticatedError = (error) => (
    error.extensions.code === 'UNAUTHENTICATED'
)

export const PAGE_INFO_FRAGMENT = gql`
    fragment PageInfo on PageInfo {
        hasNextPage
        startCursor
        endCursor
    }
`
