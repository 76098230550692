import React from 'react'
import ConfigCache from '../../util/ConfigCache'

const CurrenciesSelect = ({
    name,
    value,
    onChange,
}) => (
    <ConfigCache>
        {({ currencies }) => (
            <select
                className="select capitalize"
                name={name}
                value={value}
                onChange={onChange}
            >
                {currencies.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                        {currency.name}
                    </option>
                ))}
            </select>
        )}
    </ConfigCache>
)

export default CurrenciesSelect
