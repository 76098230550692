import { useQuery, gql } from '@apollo/client'

const MARKET_DATA_QUERY = gql`
    query myCustomerMarketData(
        $assetId: Int!,
        $assetCode: String!,
        $currencyCode: String!,
        $unitCode: String!
    ) {
        myCustomerMarketData(
            assetId: $assetId,
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode,
        ) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
        }
  }
`

const IMPURITY_MARKET_DATA_QUERY = gql`
    query myCustomerImpurityMarketData(
        $assetId: Int!,
        $assetCode: String!,
        $currencyCode: String!,
        $unitCode: String!
    ) {
        myCustomerMarketData(
            assetId: $assetId,
            assetCode: $assetCode,
            currencyCode: $currencyCode,
            unitCode: $unitCode,
        ) {
            status
            sellPrice
            previousSellPrice
            buyPrice
            previousBuyPrice
            impurities {
                impurityId
                name
                sellPrice
                previousSellPrice
                buyPrice
                previousBuyPrice
            }
        }
  }
`

const useFetchAssetCardData = (
    assetId,
    assetCode,
    currencyCode,
    unitCode,
    canTradeImpurities,
    pollInterval,
) => {
    let assetCardQuery = MARKET_DATA_QUERY
    if (canTradeImpurities === true) {
        assetCardQuery = IMPURITY_MARKET_DATA_QUERY
    }
    const {
        error,
        loading,
        data = {},
    } = useQuery(assetCardQuery, {
        variables: {
            assetId,
            assetCode,
            currencyCode,
            unitCode,
        },
        pollInterval,
    })

    const { myCustomerMarketData = {} } = data

    return {
        error,
        loading,
        myCustomerMarketData,
    }
}

export default useFetchAssetCardData
