import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import * as routes from '../../routes'

const MENU_ITEMS = [{
    id: 'balance',
    text: 'Balance',
    to: routes.balance(),
}, {
    id: 'trade',
    text: 'Trade',
    to: routes.trade(),
}, {
    id: 'orders',
    text: 'History',
    to: routes.history(),
}]

const NavbarMenu = ({
    activeMenuItem,
    className,
}) => (
    <ul
        className={
            clsx(
                'flex -mb-0.5 h-[46px]',
                className,
            )
        }
    >
        {MENU_ITEMS.map(({ id, text, to }) => {
            const isActive = id === activeMenuItem
            return (
                <li
                    key={id}
                    className={clsx(
                        'flex justify-center items-center text-sm font-normal border-b-4 border-transparent py-0 px-4',
                        isActive && 'border-primary',
                    )}
                >
                    <Link
                        className={clsx(
                            isActive && 'text-primary',
                        )}
                        to={to}
                    >
                        {text}
                    </Link>
                </li>
            )
        })}
    </ul>
)

export default NavbarMenu
