import React from 'react'
import FormState from '../../../util/FormState'
import FeatherIcon from '../../../util/FeatherIcon'
import FocusDidMount from '../../../util/FocusDidMount'

const INITIAL_STATE = {
    isChoosingAmount: false,
}

const INITIAL_VALUES = {
    amount: '1',
}

const AssetCardAction = ({
    actionText,
    onClick,
    isDisabled,
}) => (
    <div
        className="mb-4"
    >
        <FormState
            initialState={INITIAL_STATE}
            initialValues={INITIAL_VALUES}
        >
            {({
                state,
                setState,
                values,
                handleChange,
                checkValidation,
            }) => {
                if (!state.isChoosingAmount) {
                    return (
                        <button
                            type="button"
                            disabled={isDisabled}
                            className="btn-primary w-full"
                            onClick={() => onClick()}
                        >
                            {actionText}
                        </button>
                    )
                }
                return (
                    <div className="flex">
                        <FocusDidMount>
                            {(ref) => (
                                <input
                                    ref={ref}
                                    type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    className="focus:z-root w-[90px] border-r-none mr-[-1px]"
                                    value={values.amount}
                                    onChange={handleChange}
                                />
                            )}
                        </FocusDidMount>
                        <button
                            type="button"
                            className="btn-icon mr-3"
                            onClick={() => setState(INITIAL_STATE)}
                        >
                            <FeatherIcon name="cross" />
                        </button>
                        <button
                            type="button"
                            disabled={isDisabled}
                            className="btn-primary flex-1"
                            onClick={async () => {
                                if (checkValidation()) {
                                    onClick(values.amount)
                                }
                            }}
                        >
                            {actionText}
                        </button>
                    </div>
                )
            }}
        </FormState>
    </div>
)

export default AssetCardAction
