import React from 'react'
import Container from '../../util/Container'
import OnboardingForm from './OnboardingForm'

const OnboardingPage = ({
    formState,
    isSubmitting,
    onSubmit,
}) => (
    <Container>
        <div className="py-8 mx-auto max-w-[375px]">
            <OnboardingForm
                formState={formState}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
            />
        </div>
    </Container>
)

export default OnboardingPage
