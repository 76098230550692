import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import * as cache from '../util/cache'
import { isUnauthenticatedError } from '../util/graphql'

const setAuthContext = async (request, { headers }) => {
    if (cache.accessToken !== null) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${cache.accessToken}`,
            },
        }
    }

    await cache.loadAccessToken()

    if (cache.accessToken === null) {
        return { headers }
    }
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${cache.accessToken}`,
        },
    }
}

const removeAccessTokenFromCacheLink = onError((error) => {
    if (
        typeof error.networkError !== 'undefined'
        && error.networkError.statusCode === 400
        && typeof error.graphQLErrors !== 'undefined'
        && error.graphQLErrors.length > 0
        && isUnauthenticatedError(error.graphQLErrors[0])
    ) {
        cache.removeAccessToken()
    }
})

export default function createApolloClient() {
    const { REACT_APP_SERVER_URI } = window.env
    const httpLink = new HttpLink({
        uri: `${REACT_APP_SERVER_URI}/graphql/client`,
    })
    const setAuthorizationHeadersLink = setContext(setAuthContext)
    const linkFlow = setAuthorizationHeadersLink.concat(
        removeAccessTokenFromCacheLink.concat(
            httpLink,
        ),
    )
    return new ApolloClient({
        link: linkFlow,
        cache: new InMemoryCache(),
    })
}

export const createUnauthorizedApolloClient = () => (
    new ApolloClient({
        cache: new InMemoryCache(),
        link: new HttpLink({
            uri: `${window.env.REACT_APP_SERVER_URI}/graphql/client`,
        }),
    })
)
