export const colorWhite = '#FFFFFF'
export const colorBlack = '#1F2D3D'

export const colorSmoke = '#E0E6ED'
export const colorSmokeDark = '#D3DCE6'
export const colorSmokeDarker = '#C0CCDA'

export const colorSnow = '#F9FAFC'
export const colorSnowDark = '#EFF2F7'
export const colorSnowDarker = '#E5E9F2'

export const colorSilver = '#8492A6'
export const colorSlate = '#3C4858'
export const colorSteal = '#273444'

export const colorBlueLightest = '#e5f7ff'
export const colorBlueLight = '#85D7FF'
export const colorBlue = '#1FB6FF'
export const colorBlueDark = '#009EEB'

export const colorYellowLightest = '#fff8e5'
export const colorYellowLight = '#FFD55F'
export const colorYellow = '#FFC82C'
export const colorYellowDark = '#F8B700'

export const colorRedLightest = '#ffe5e5'
export const colorRedLight = '#FF4949'
export const colorRed = '#FF4949'
export const colorRedDark = '#FF4949'

export const colorGreenLightest = '#e8fdf1'
export const colorGreenLight = '#29EB7F'
export const colorGreen = '#13CE66'
export const colorGreenDark = '#0F9F4F'

export const colorPrimaryLight = colorSlate
export const colorPrimary = colorBlack
export const colorPrimaryDark = colorBlack

export const colorSecondaryLight = '#DCAC11'
export const colorSecondary = '#DCAC11'
export const colorSecondaryDark = '#DCAC11'

export const colorGreyLighter = '#F4F5F7'
export const colorGreyLight = '#EBECEE'
export const colorGrey = colorSmoke
export const colorGreyDark = '#9C9C9C'
export const colorGreyDarker = '#767676'

export const spacingTiny = 0.4
export const spacingSmallest = 0.8
export const spacingSmaller = 1
export const spacingSmall = 1.2
export const spacing = 1.6
export const spacingLarge = 3.2
export const spacingLarger = 5
export const spacingLargest = 6.4

export const borderRadius = 0.4
export const borderRadiusLarge = 0.8

export const fontFamilySerif = '\'Playfair Display\', serif'
export const fontFamilySansSerif = '\'Roboto\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif'
export const fontFamilyMonospace = 'Menlo, Monaco, Consolas, \'Liberation Mono\', \'Courier New\', monospace'

export const fontSizeSmallest = 1
export const fontSizeSmaller = 1.2
export const fontSizeSmall = 1.4
export const fontSize = 1.6
export const fontSizeLarge = 2.2
export const fontSizeLarger = 2.8
export const fontSizeLargest = 3.6
export const fontSizeBig = 4.6

export const fontWeightThin = 100
export const fontWeightLight = 300
export const fontWeightRegular = 400
export const fontWeightMedium = 500
export const fontWeightBold = 700
export const fontWeightBlack = 900

export const boxShadow = '0px 2px 5px 0px rgba(0,0,0,0.12);'
export const boxShadowFar = '0px 5px 10px 0px rgba(0,0,0,0.12);'

export const defaultFontFamily = fontFamilySansSerif
export const defaultFontWeight = fontWeightLight
export const defaultFontSize = fontSize
export const defaultFontColor = colorSilver
export const defaultLetterSpacing = 0
export const defaultLineHeight = 1.65
export const defaultSpacing = spacing
export const defaultBorderColor = colorGrey
export const defaultBorderWidth = 0.1
export const defaultBackgroundColor = colorWhite
export const defaultBorderRadius = borderRadius
export const defaultInputHeight = 3.6
export const defaultBoxShadow = boxShadow

export const iconSizeLarge = 48 // px
export const iconSizeLarger = 60 // px

export const zIndexRoot = 1
export const zIndexNavBar = 2
export const zIndexModalBackground = 3
export const zIndexModal = 4

export const maxWidth = 104

export const phoneBoundary = 600
export const tabletPortraitBoundary = 900
export const tabletLandscapeBoundary = 1200
export const tabletDesktopBoundary = 1800

export const mediaForPhoneOnly = `max-width: ${phoneBoundary - 1}px`
export const mediaForTabletPortrait = `min-width: ${phoneBoundary}px`
export const mediaForTabletLandscape = `min-width: ${tabletPortraitBoundary}px`
export const mediaForDesktop = `min-width: ${tabletLandscapeBoundary}px`
export const mediaForLargeDesktop = `max-width: ${tabletDesktopBoundary}px`
