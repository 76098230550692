import React, { useState } from 'react'
import { where } from '../../../../util'
import useFetchAssetCardData from './useFetchAssetCardData'
import AssetCardLoading from './AssetCardLoading'
import AssetCardError from './AssetCardError'
import AssetCardSuspended from './AssetCardSuspended'
import AssetCardUnavailable from './AssetCardUnavailable'
import AssetCardWithModal from './AssetCardWithModal'

const POLL_FREQUENCY = 1000 * 3 // 3 sec

const STATUS_UNAVAILABLE = 'UNAVAILABLE'
const STATUS_SUSPENDED = 'SUSPENDED'

const whereCode = where('code')

const AssetCardWithState = ({
    currentUser,
    asset,
    canSell,
    canBuy,
    availableCurrencies,
    availableUnits,
}) => {
    const availableCurrencyCodes = availableCurrencies.map(({ code }) => code)
    const availableUnitCodes = availableUnits.map(({ code }) => code)
    let [initialCurrencyCode] = availableCurrencyCodes
    let [initialUnitCode] = availableUnitCodes
    if (availableCurrencyCodes.includes(currentUser.preferredCurrencyCode)) {
        initialCurrencyCode = currentUser.preferredCurrencyCode
    }
    if (availableUnitCodes.includes(currentUser.preferredUnitCode)) {
        initialUnitCode = currentUser.preferredUnitCode
    }
    const [currencyCode, setCurrencyCode] = useState(initialCurrencyCode)
    const [unitCode, setUnitCode] = useState(initialUnitCode)
    const [isShowingKarats, setIsShowingKarats] = useState(false)
    const {
        error,
        loading,
        myCustomerMarketData,
    } = useFetchAssetCardData(asset.id, asset.code, currencyCode, unitCode, asset.canTradeImpurities, POLL_FREQUENCY)

    if (loading) {
        return <AssetCardLoading />
    }
    if (typeof error !== 'undefined') {
        return <AssetCardError />
    }
    if (myCustomerMarketData.status === STATUS_SUSPENDED) {
        return <AssetCardSuspended assetName={asset.name} />
    }
    if (myCustomerMarketData.status === STATUS_UNAVAILABLE) {
        return <AssetCardUnavailable assetName={asset.name} />
    }
    const selectedCurrency = availableCurrencies.find(whereCode(currencyCode))

    return (
        <AssetCardWithModal
            asset={asset}
            canSell={canSell}
            canBuy={canBuy}
            marketData={myCustomerMarketData}
            unitCode={unitCode}
            currencyCode={currencyCode}
            currencySymbol={selectedCurrency.realSymbol}
            availableCurrencies={availableCurrencies}
            availableUnits={availableUnits}
            minTradeLimit={currentUser.customer.minTradeLimit || currentUser.customer.tradeLimit.minTradeLimit}
            maxTradeLimit={currentUser.customer.maxTradeLimit || currentUser.customer.tradeLimit.maxTradeLimit}
            onChangeUnitCode={(newUnitCode) => setUnitCode(newUnitCode)}
            onChangeCurrencyCode={(newCurrencyCode) => setCurrencyCode(newCurrencyCode)}
            onToggleKarats={() => setIsShowingKarats(!isShowingKarats)}
            isShowingKarats={isShowingKarats}
        />
    )
}

export default AssetCardWithState
