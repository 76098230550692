/* eslint-disable react/no-unused-state */

import React from 'react'
import logout from '../api/logout'
import * as storage from '../util/cache'
import { loadAndValidateTokens } from '../util/auth'
import { scopeSentryUser, removeScopedSentryUser } from '../sentry/scopeUser'
import { scopeIntercomUser, removeIntercomUser, updateIntercomUser } from '../intercom/scopeIntercomUser'
import { getNotificationDispatcher } from '../util/NotificationDispatcher'
import getHealth from '../api/getHealth'
import getInitialData from '../api/getInitialData'
import App from './App'
import AppProviders from './AppProviders'

class AppWithState extends React.Component {
    constructor(props) {
        super(props)

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.refresh = this.refresh.bind(this)
        this.fetchInitialData = this.fetchInitialData.bind(this)

        this.notificationDispatcher = getNotificationDispatcher()

        this.state = {
            isAvailable: true,
            isAppLoading: true,
            isLoadingTokens: true,
            isAuthenticated: false,
            currentUser: null,
            isLoadingInitialData: false,
            login: this.login,
            logout: this.logout,
            refresh: this.refresh,
            fetchInitialData: this.fetchInitialData,
            hasHealthNetworkError: false,
            checkAppHealth: this.checkAppHealth,
        }
    }

    async componentDidMount() {
        const { apolloClient } = this.props
        try {
            await this.checkAppHealth()
            await this.checkAuthorizationState()
            await apolloClient.resetStore()
            await this.fetchInitialData()
            await this.initServices()
        } catch (error) {
            throw new Error(error.message)
        } finally {
            this.setState({
                isAppLoading: false,
            })
        }
    }

    updateUserContext() {
        const { currentUser } = this.state
        scopeSentryUser(currentUser)
        updateIntercomUser(currentUser)
    }

    removeUserContext() {
        removeScopedSentryUser()
        removeIntercomUser()
    }

    async checkAuthorizationState() {
        const { accessToken } = await loadAndValidateTokens()
        const isAuthenticated = accessToken !== null
        this.setState({
            isLoadingTokens: false,
            isAuthenticated,
            isLoadingInitialData: isAuthenticated,
        })
    }

    async checkAppHealth() {
        try {
            const { health } = await getHealth()
            this.setState({
                isAvailable: health.customerAppIsAvailable,
            })
        } catch (error) {
            this.setState({
                hasHealthNetworkError: true,
            })
        }
    }

    async fetchInitialData() {
        const { apolloClient } = this.props
        const { isAuthenticated } = this.state
        if (isAuthenticated) {
            const {
                me: currentUser,
            } = await getInitialData(apolloClient)
            if (currentUser === null) {
                await this.logout()
                return
            }
            this.setState({
                currentUser,
                isLoadingInitialData: false,
            })
        }
    }

    async initServices() {
        const { isAuthenticated, currentUser } = this.state
        if (isAuthenticated && currentUser !== null) {
            scopeSentryUser(currentUser)
            scopeIntercomUser(currentUser)
        }
    }

    async login(accessToken, refreshToken, currentUser) {
        await Promise.all([
            storage.setAccessToken(accessToken),
            storage.setRefreshToken(refreshToken)
        ])
        const isAuthenticated = accessToken !== null
        this.setState({
            isAuthenticated,
            currentUser,
        })
        await this.initServices()
    }

    async logout() {
        const { apolloClient } = this.props
        this.setState({ isAuthenticated: false })
        this.removeUserContext()
        if (storage.refreshToken) {
            await logout(storage.refreshToken)
        }
        await Promise.all([
            storage.removeAccessToken(),
            storage.removeRefreshToken()
        ])
        await apolloClient.resetStore()
    }

    async refresh() {
        const { apolloClient } = this.props
        this.setState({
            isLoadingTokens: true,
            isAuthenticated: false,
            currentUser: null,
            isLoadingInitialData: false,
        })
        await this.checkAppHealth()
        await this.checkAuthorizationState()
        await apolloClient.stop()
        await apolloClient.resetStore()
        await this.fetchInitialData()
        this.updateUserContext()
    }

    render() {
        const { apolloClient } = this.props
        return (
            <AppProviders
                appState={this.state}
                notificationDispatcher={this.notificationDispatcher}
                apolloClient={apolloClient}
            >
                <App />
            </AppProviders>
        )
    }
}

export default AppWithState
